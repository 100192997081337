import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
// import { Helmet } from "react-helmet";
import { Box, Button, Fab, Dialog, DialogContent, Grid, Icon, IconButton, Switch, Typography, TextField } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { AppSingleton } from "../services/user_config";
import { getDeviceType } from "../utils/CommonUtils";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html, useGLTF } from "@react-three/drei";
import { getRoute } from "../utils/CommonUtils";
import { ModelPreview } from "../components/ModelPreview";
import { MODEL_PREVIEWS } from "../data/ar-data";

// import * as THREE from "three";

// const models = [
//   // { key: "mei-ho-house-ancient-c", name: "美荷樓 (翻新前)" },
//   // { key: "mei-ho-house-modern-c", name: "美荷樓 (翻新後)" },
//   {
//     key: "jccac-ancient-c",
//     name: "石硤尾大廈 (翻新前)",
//     scale: { x: 0.05, y: 0.05, z: 0.05 },
//     position: { x: 0.0, y: -2.0, z: 0.0 },
//   },
//   {
//     key: "jccac-modern-c",
//     name: "石硤尾大廈 (翻新後)",
//     scale: { x: 0.05, y: 0.05, z: 0.05 },
//     position: { x: 0.0, y: -2.0, z: 0.0 },
//   },
//   {
//     key: "garden-company",
//     name: "嘉頓深水埗總部",
//     scale: { x: 0.05, y: 0.05, z: 0.05 },
//     position: { x: 0.0, y: -2.0, z: 0.0 },
//   },
//   // { key: "pls", name: "屯門大峽谷 - 菠蘿山", scale: { x: 16.0, y: 16.0, z: 16.0 }, position: { x: 0.0, y: -2.0, z: 0.0 } },
//   { key: "pls", name: "屯門大峽谷 - 菠蘿山", scale: { x: 0.01, y: 0.01, z: 0.01 }, position: { x: 0.0, y: -1.0, z: 0.0 } },
//   {
//     key: "bucket-steel",
//     name: "舊金屬桶",
//     scale: { x: 1.0, y: 1.0, z: 1.0 },
//     position: { x: 0.0, y: -2.0, z: 0.0 },
//     metalness: 1.0,
//     roughness: 0.1,
//   },
//   {
//     key: "bucket-plastic",
//     name: "舊鐵桶",
//     scale: { x: 2.0, y: 2.0, z: 2.0 },
//     position: { x: 0.0, y: -2.0, z: 0.0 },
//     metalness: 1.0,
//     roughness: 0.1,
//   },
//   {
//     key: "sewing-machine-with-table",
//     name: "上一代手動衣車",
//     scale: { x: 3.0, y: 3.0, z: 3.0 },
//     position: { x: 0.0, y: -3.0, z: 0.0 },
//     metalness: 1.0,
//     roughness: 0.01,
//   },
// ];

// const Model = ({ glbDict, canvasRef }) => {
//   console.log("glbDict");
//   console.log(glbDict);
//   const { scene, materials } = useGLTF(`/models/preview/${glbDict?.key}.glb` ?? "");

//   scene.scale.set(glbDict?.scale?.x, glbDict?.scale?.y, glbDict?.scale?.z);
//   scene.position.set(glbDict?.position?.x, glbDict?.position?.y, glbDict?.position?.z);

//   // Make the object shiny, if the model's materials are not already set, you can do this:
//   if (glbDict?.metalness || glbDict?.roughness)
//     scene.traverse((child) => {
//       if (child.isMesh) {
//         if (glbDict?.metalness) {
//           child.material.metalness = glbDict?.metalness; // Higher values give more reflective surfaces
//         }
//         if (glbDict?.roughness) {
//           child.material.roughness = glbDict?.roughness; // Lower values give a shinier effect
//         }
//       }
//     });

//   // scene.position.set(0.0, -2.0, 0.0);

//   useEffect(() => {
//     return () => {
//       Object.values(materials).forEach((material) => {
//         if (material.dispose) {
//           material.dispose();
//         }
//       });
//       scene.traverse((child) => {
//         if (child.isMesh) {
//           child.geometry.dispose();
//         }
//       });
//     };
//   }, [scene, materials]);

//   return glbDict ? <primitive object={scene} /> : null;
// };

const ImagePreview = ({ filename, name }) => {
  return (
    <Box sx={{ borderRadius: "8px", border: "2px solid #007BFF" }}>
      <img src={`/models/preview/${filename}.png`} alt={`${name}`} style={{ width: "100%", height: "auto" }} />
      <Typography sx={{ textAlign: "center", mb: 1 }}>{name}</Typography>
    </Box>
  );
};

const ModelPreviewPage = () => {
  const navigate = useNavigate();

  const [deviceType, setDeviceType] = useState(getDeviceType());
  // const [glbUrl, setGlbUrl] = useState(null);
  // const [glbScale, setGlbScale] = useState({ x: 1.0, y: 1.0, z: 1.0 });
  // const [glbTitle, setGlbTitle] = useState("");
  const [glbDict, setGlbDict] = useState(null);
  const canvasRef = useRef(null);

  const appSingleton = new AppSingleton();

  return (
    <Box sx={{ width: "100%", height: "100vh", overflowY: "auto", overflowX: "hidden" }}>
      <Fab
        aria-label="back"
        sx={{
          position: "absolute",
          top: 32,
          left: 32,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          navigate(`${getRoute("home-page")}`);
        }}
      >
        <ArrowBackIosNew />
      </Fab>
      <Box sx={{ m: 6 }}>
        <Grid container spacing={4} justifyContent="center" alignItems="stretch">
          {MODEL_PREVIEWS.map((_model) => (
            <Grid item xs={12} sm={6} md={4} key={_model?.key}>
              {deviceType === "ios" ? (
                <a href={`/models/preview/${_model?.key}.usdz`} rel="ar">
                  <ImagePreview filename={_model?.key} name={_model?.name} />
                </a>
              ) : (
                <Box onClick={() => setGlbDict({ ..._model })}>
                  <ImagePreview filename={_model?.key} name={_model?.name} />
                </Box>
              )}
            </Grid>
          ))}
        </Grid>
      </Box>

      <Dialog
        open={!!glbDict}
        onClose={() => setGlbDict(null)}
        PaperProps={{
          style: { width: "95%", backgroundColor: "#cccccc" },
        }}
      >
        <DialogContent>
          <Canvas ref={canvasRef} style={{ width: "100%", height: "80vh" }}>
            <ambientLight intensity={2.0} />
            <pointLight position={[2, 2, 0]} intensity={100} decay={2} distance={30} />
            {glbDict ? <ModelPreview glbDict={glbDict} canvasRef={canvasRef} /> : null}

            {/* // MARK: perspectiveCamera not working */}
            {/* <perspectiveCamera
              makeDefault
              position={[0, 20, 0]} // Higher position
              rotation={[-0.9, 0, 0]} // Look downwards (adjust as needed)
            /> */}
            <OrbitControls />
            <Html
              // center
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                width: "100%",
                textAlign: "center",
              }}
            >
              <h1
                style={{
                  whiteSpace: "nowrap",
                  color: "white",
                  textShadow: "-2px -2px 0 black, 2px -2px 0 black,-2px 2px 0 black, 2px 2px 0 black",
                  margin: 0,
                }}
              >
                {glbDict?.name}
              </h1>
            </Html>
          </Canvas>
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const f = () => {};

export { ModelPreviewPage, f };
