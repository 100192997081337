import React, { useRef, useState, useEffect } from "react";
import { OrbitControls, Html, useGLTF } from "@react-three/drei";
import { GLTFLoader } from "three/examples/jsm/loaders/GLTFLoader";

const ModelPreview = ({ glbDict, canvasRef, fmPage }) => {
  // useGLTF.preload(`/models/preview/${glbDict?.key}.glb` ?? "", GLTFLoader);

  const { scene, materials } = useGLTF(`/models/preview/${glbDict?.key}.glb` ?? "");

  scene.scale.set(glbDict?.scale?.x, glbDict?.scale?.y, glbDict?.scale?.z);

  const positionKey = fmPage === "listing" ? "positionListing" : "position";
  scene.position.set(glbDict[positionKey]?.x, glbDict[positionKey]?.y, glbDict[positionKey]?.z);

  // Make the object shiny, if the model's materials are not already set, you can do this:
  if (glbDict?.metalness || glbDict?.roughness)
    scene.traverse((child) => {
      if (child.isMesh) {
        if (glbDict?.metalness) {
          child.material.metalness = glbDict?.metalness; // Higher values give more reflective surfaces
        }
        if (glbDict?.roughness) {
          child.material.roughness = glbDict?.roughness; // Lower values give a shinier effect
        }
      }
    });

  useEffect(() => {
    return () => {
      Object.values(materials).forEach((material) => {
        if (material.dispose) {
          material.dispose();
        }
      });
      scene.traverse((child) => {
        if (child.isMesh) {
          child.geometry.dispose();
        }
      });
    };
  }, [scene, materials]);

  return glbDict ? <primitive object={scene} /> : null;
};

const f = () => {};

export { ModelPreview, f };
