import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Box, Button, Fab, Grid, Icon, IconButton, Switch, Typography, TextField } from "@mui/material";
import { ColorLens, ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
// import { AFrameRenderer, Marker } from "react-web-ar";
import { AFrameRenderer } from "./AFrameRenderer.js";
// import informationIcon from "./information-icon.png";
// import scanInput2 from "./scan-input-2.png";
// import "aframe-mouse-cursor-component";
import { calculateDistance, getDeviceType, getRoute } from "../utils/CommonUtils";
import { getModelUrl } from "../utils/ModelUtils";
import { backendEndPoint, httpEndpoint, isDebug } from "../services/backend";
import { IntroductoryAvatar } from "../components/IntroductoryAvatar";
import { AppSingleton } from "../services/user_config";
import "./ar-ssp-page.css";

const isCRM = true;
const isVideo = false;

const addEntity = ({ markerRef, entityRef, url, defaultScale }) => {
  var entity = document.createElement("a-entity");
  entity?.setAttribute("gltf-model", `url(${url})`);
  entity?.setAttribute("scale", `${defaultScale} ${defaultScale} ${defaultScale}`);
  entity?.setAttribute("rotation", `1 1 1`);
  entity?.setAttribute("z-index", "9999");
  if (entity?.components["look-controls"]) {
    entity.components["look-controls"].enabled = false;
    entity.removeAttribute("look-controls");
  }
  // console.log("downloading gltf prgramiically ");
  markerRef.current.appendChild(entity);
  entity?.setAttribute("visible", "false");
  // entity.object3D.visible = false;
  entityRef.current = entity;
};

// const addCamera = ({ parent }) => {
//   var camera = document.createElement("a-camera");
//   camera?.setAttribute("look-controls", "reverseMouseDrag:true; touchEnabled: false");
//   parent.appendChild(camera);
// };

const addMarker = ({ url, key }) => {
  const scene = document.querySelector("a-scene");

  scene?.setAttribute("embedded", "");
  scene?.setAttribute("xr-mode-ui", "enabled: false");
  scene?.setAttribute("arjs", "trackingMethod: best;");
  // scene?.setAttribute("width", document.querySelector("body").clientWidth);
  // scene?.setAttribute("height", document.querySelector("body").clientHeight);

  // scene?.setAttribute("renderer", "antialias: true; alpha: true");
  // scene?.setAttribute("cursor", "rayOrigin: mouse");
  // scene?.setAttribute("raycaster", "near: 0; far: 50000");
  // scene?.setAttribute("gesture-detector", "");

  var marker = document.createElement("a-marker-camera");
  marker?.setAttribute("type", "pattern");
  marker?.setAttribute("preset", "custom");
  marker?.setAttribute("emitevents", true);
  // marker?.setAttribute("smoothTolerance", 0.01);
  marker?.setAttribute("size", 45);
  // marker?.setAttribute("pattratio", 4.5 / 100);
  marker?.setAttribute("smoothCount", 20);
  // marker?.setAttribute("cursor", "rayOrigin: mouse");
  // marker?.setAttribute("raycaster", "near: 0; far: 50000");
  // marker?.setAttribute("cursor", "rayOrigin: mouse");

  // marker.addEventListener("click", (e) => {
  //   console.log("marker click!");
  //   console.log(e);
  //   const intersectedElement = e && e.detail && e.detail.intersectedEl;
  //   console.log(intersectedElement);
  // });
  // marker?.setAttribute("markerhandler", "");
  // marker?.setAttribute("smoothTolerance", 0.01);
  // marker?.setAttribute("smoothThreshold", 2);
  if (url) {
    marker?.setAttribute("url", url);
  }
  if (key) {
    marker?.setAttribute("url", getModelUrl({ key: key }));
  }
  scene?.appendChild(marker);

  // MARK: Add cursor to allow for clicking and basic interactivity with a scene on devices
  // const cursor = document.createElement("a-cursor");
  // marker.appendChild(cursor);

  // addCamera({ parent: marker });

  // MARK: a-assets to preload if needed
  const assets = document.createElement("a-assets");
  scene?.appendChild(assets);

  // var box = document.createElement("a-box");
  // box?.setAttribute("position", "0 0.5 0");
  // box?.setAttribute("scale", "1 1 1");
  // box?.setAttribute("material", "opacity: 0.5;");
  // marker.appendChild(box);

  return { marker, scene };
};

// const addVideoRuntime = ({ markerRef, url }) => {
//   aVideoRef.current?.components?.material?.material?.map?.image?.pause();
//   aVideoRef.current?.setAttribute("src", _entityDict?.videoUrl);
//   console.log(_entityDict);
//   console.log("videoLink");
//   console.log(_entityDict?.videoUrl);
// };

const addEntityRuntime = ({ markerRef, data }) => {
  var entity = document.createElement("a-entity");
  let extraEntities = [];
  if (data) {
    entity?.setAttribute("gltf-model", `url(${data?.url})`);
    // entity?.setAttribute("gltf-model", getModelUrl({ key: data?.id }));
    entity?.setAttribute("scale", `${data?.defaultScale} ${data?.defaultScale} ${data?.defaultScale}`);
    // entity?.setAttribute("position", "0 0 0");
    entity?.setAttribute("position", `${data?.defaultPosition.x} ${data?.defaultPosition.y} ${data?.defaultPosition.z}`);
    entity?.setAttribute("rotation", `1 1 1`);
    entity?.setAttribute("z-index", "9999");
    // MARK: set anchor for WebXR
    entity?.setAttribute("anchored", "persistent: true");
    // entity?.setAttribute("camera", "");
    // entity?.setAttribute("look-controls", "");
    markerRef.current.appendChild(entity);
    entity?.setAttribute("visible", "false");

    data?.extras?.forEach((_extra) => {
      if (_extra?.type.toLowerCase() === "ball-blue-small") {
        const aSphere = document.createElement("a-sphere");
        // aSphere?.setAttribute("anchored", "persistent: true");
        aSphere?.setAttribute("color", "blue");
        aSphere?.setAttribute("radius", "1");
        aSphere?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        // aSphere?.setAttribute("cursor", "rayOrigin: mouse");
        // aSphere?.setAttribute("cursor", "");
        aSphere?.setAttribute("class", "sphere");
        aSphere?.setAttribute("sphere-click", "");
        // aSphere.addEventListener("click", (e) => {
        //   console.log("sphere click!");
        //   console.log(e);
        //   // const intersectedElement = e && e.detail && e.detail.intersectedEl;
        //   // console.log(intersectedElement);
        // });
        aSphere?.setAttribute(
          "position",
          `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`
        );
        aSphere?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(aSphere);
        aSphere?.setAttribute("visible", "false");
        extraEntities.push(aSphere);

        // MARK: for box click event
        // const sphereRayCaster = document.createElement("a-entity");
        // sphereRayCaster?.setAttribute(
        //   "position",
        //   `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`
        // );
        // sphereRayCaster?.setAttribute("traceId", "sphere");
        // sphereRayCaster?.setAttribute("raycaster", "objects: .sphere; showLine: true;");
        // sphereRayCaster?.setAttribute("cursor", "");
        // markerRef.current.appendChild(sphereRayCaster);
      } else if (_extra?.type.toLowerCase() === "box-red-small") {
        const box = document.createElement("a-box");
        // box?.setAttribute("anchored", "persistent: true");
        box?.setAttribute("color", "red");
        box?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        // box?.setAttribute("id", "box-red-small");
        // box?.setAttribute("cursor", "");
        // box?.setAttribute("cursor", "rayOrigin: mouse");
        box?.setAttribute("class", "box");
        box?.setAttribute("box-click", "");
        // box.addEventListener("click", (e) => {
        //   console.log("box click!");
        //   console.log(e);
        //   // const intersectedElement = e && e.detail && e.detail.intersectedEl;
        //   // console.log(intersectedElement);
        // });
        box?.setAttribute("position", `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`);
        box?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(box);
        box?.setAttribute("visible", "false");
        extraEntities.push(box);
        // MARK: for box click event
        // const boxRayCaster = document.createElement("a-entity");
        // boxRayCaster?.setAttribute(
        //   "position",
        //   `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`
        // );
        // boxRayCaster?.setAttribute("traceId", "box");
        // boxRayCaster?.setAttribute("raycaster", "objects: .box; showLine: true;");
        // boxRayCaster?.setAttribute("cursor", "");
        // markerRef.current.appendChild(boxRayCaster);
      }
    });
  }
  return { entity, extraEntities };
};

const disposeAllEntities = () => {
  // console.log("disposeAllEntities");
  const entities = document.querySelectorAll("a-entity");
  // const markers = document.querySelectorAll("a-marker");
  const markers = document.querySelectorAll("a-marker-camera");
  entities?.forEach((entity) => {
    entity.object3DMap.mesh.dispose();
    entity.parentNode.removeChild(entity);
  });

  markers?.forEach((marker) => {
    marker.parentNode.removeChild(marker);
  });
};

const dataList = [
  {
    id: "mei-ho-house",
    name: "石硤尾邨 - 美荷樓",
    entity: {
      ancient: {
        id: "mei-ho-house-ancient",
        // url: "https://survey.reality-connect.tech/models/mei-ho-house/Ancient-c.glb",
        url: `${httpEndpoint}/models/mei-ho-house/Ancient-c.glb`,
        videos: [{ name: "石硤尾邨 - 美荷樓 - 翻新前", videoUrl: `${httpEndpoint}/videos/mei-ho-house/Ancient.mp4` }],
        desc: "美荷樓的誕生源於1953年12月25日夜晚在石硤尾發生的一場大火（後通稱「石硤尾大火」）。大火發生在石硤尾山邊寮屋區，使接近58,000人喪失家園。為了向災民提供長期的房屋設施，包括美荷樓在內的首批8座六層高的徙置大廈應運而生。為了改善居民生活，邨內其他地方已進行重建，而這座被列為香港二級歷史建築的美荷樓則於2005年關閉，直至2013年才改建成為青年旅舍，並設立了1間生活館。當前，美荷樓主題展覽「歲月留情」更加入口述歷史及各類型展品，以描繪50至60年代基層市民的生活世態，復刻香港精神的原點。",
        // url: "http://192.168.0.220:8004/models/mei-ho-house/Ancient-c.glb",
        defaultScale: 1.5, //0.65,
        // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+; z = far- near+
        defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
      },
      modern: {
        id: "mei-ho-house-modern",
        // url: "https://survey.reality-connect.tech/models/mei-ho-house/Modern-c.glb",
        url: `${httpEndpoint}/models/mei-ho-house/Modern-c.glb`,
        videos: [{ name: "石硤尾邨 - 美荷樓 - 翻新後", videoUrl: `${httpEndpoint}/videos/mei-ho-house/Modern.mp4` }],
        desc: "美荷樓生活館迎來全新面貌的展館，新展覽將會帶大家回到過去，以不同方式讓大眾仿如置身舊日社區，更投入地認識和了解居民生活情景、昔日街坊情和美荷樓的蜕變。歡迎前來參觀，發掘精彩展覽內容！由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。",
        // url: "http://192.168.0.220:8004/models/mei-ho-house/Modern-c.glb",
        defaultScale: 1.5, //0.65,
        // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+
        defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
      },
    },
  },
  {
    id: "jccac",
    name: "石硤尾工廠大廈",
    entity: {
      ancient: {
        id: "jccac-ancient",
        // url: "https://survey.reality-connect.tech/models/jccac/Ancient-c.glb",
        url: `${httpEndpoint}/models/jccac/Ancient-c.glb`,
        videos: [{ name: "石硤尾工廠大廈 - 翻新前", videoUrl: `${httpEndpoint}/videos/jccac/Ancient.mp4` }],
        desc: "JCCAC 的大樓前身是1977 年落成的政府工廈「石硤尾工廠大廈」，當時容納許多謔稱「山寨廠」的家庭式手工業。隨著香港輕工業逐漸式微，工廈單位逐漸被丟空，後得政府提供場地及香港賽馬會慈善信託基金贊助改建項目（並因此命名），這幢舊工廈搖身一變成為樓高九層佔地約二十萬平方呎的重要藝文空間，改建項目並榮獲香港建築師學會2008「全年境內建築大獎」。JCCAC是自負盈虧非牟利慈善團體、香港浸會大學附屬機構，及香港藝術發展局和香港藝術中心的策略夥伴。",
        // url: "http://192.168.0.220:8004/models/jccac/Ancient-c.glb",
        defaultScale: 0.04, // MARK: 0.02
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
      },
      modern: {
        id: "jccac-modern",
        // url: "https://survey.reality-connect.tech/models/jccac/Modern-c.glb",
        url: `${httpEndpoint}/models/jccac/Modern-c.glb`,
        videos: [{ name: "石硤尾工廠大廈 - 翻新後", videoUrl: `${httpEndpoint}/videos/jccac/Modern.mp4` }],
        desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
        // url: "http://192.168.0.220:8004/models/jccac/Modern-c.glb",
        defaultScale: 0.04, // MARK: 0.02
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
      },
    },
  },
  {
    id: "garden-company",
    name: "嘉頓深水埗總部",
    entity: {
      ancient: {
        id: "garden-company-ancient",
        url: `${httpEndpoint}/models/garden-company/ancient.glb`,
        videos: [],
        desc: "嘉頓有限公司（The Garden Company Limited，簡稱嘉頓）是一家在香港知名且歷史悠久的食品公司，主要生產麵包、餅乾和蛋糕等食品，其著名產品包括“生命麵包”和“雪芳蛋糕”等。公司成立於1926年，由張子芳和黃華岳創立，因在香港植物園討論計劃而得名“Garden”，後在不同地點擴展，並推出富含維生素和礦物質的創新產品如“生命麵包”。",
        defaultScale: 0.5, // MARK: 12.0
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
      },
    },
  },
  // {
  //   id: "poloshan",
  //   name: "屯門大峽谷 - 菠蘿山",
  //   entity: {
  //     ancient: {
  //       id: "poloshan-ancient",
  //       // url: "https://survey.reality-connect.tech/models/poloshan/Ancient-c.glb",
  //       url: `${httpEndpoint}/models/poloshan/Ancient-c.glb`,
  //       videos: [
  //         { name: "菠蘿山 - 1", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#FFAC98" },
  //         { name: "菠蘿山 - 2", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#F5F4E1" },
  //         { name: "菠蘿山 - 3", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#E9F4F6" },
  //       ],
  //       desc: "菠蘿山，又被稱為「香港的大峽谷」，位於良田坳峽谷，是屯門區的一座小山丘，海拔高達121米。它座落於山景邨和良景邨之間，每年吸引了許多香港人和遊客前來遠足、郊遊和拍照留念。菠蘿山是香港重要的本土文化和歷史景點，承載著豐富的歷史內涵和文化價值。過去，這個地區是屯門地區的農田和果園，而現在則成為人們休閒娛樂的場所。透過虛擬實景技術，我們可以透過手機更深入地了解菠蘿山地貌的獨特之處。",
  //       // url: "http://192.168.0.220:8004/models/jccac/Ancient-c.glb",
  //       defaultScale: 24.0, // MARK: 12.0
  //       defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
  //       // extras: [
  //       //   {
  //       //     id: "poloshan-ancient-ball-1",
  //       //     type: `ball-blue-small`,
  //       //     url: ``,
  //       //     onClickType: `play-video-1`,
  //       //     defaultPosition: { x: 0.5, y: 1, z: 0.5 },
  //       //     defaultScale: 0.05,
  //       //   },
  //       //   {
  //       //     id: "poloshan-ancient-ball-2",
  //       //     type: `box-red-small`,
  //       //     url: ``,
  //       //     onClickType: `play-video-2`,
  //       //     defaultPosition: { x: 0.2, y: 1, z: 0.5 },
  //       //     defaultScale: 0.05,
  //       //   },
  //       // ],
  //     },
  //     // modern: {
  //     //   id: "jccac-modern",
  //     //   url: "https://survey.reality-connect.tech/models/jccac/Modern-c.glb",
  //     //   desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
  //     //   // url: "http://192.168.0.220:8004/models/jccac/Modern-c.glb",
  //     //   defaultScale: 0.02,
  //     // },
  //   },
  // },
  // {
  //   id: "bucket-plastic",
  //   name: "舊鐵桶",
  //   entity: {
  //     ancient: {
  //       id: "bucket-plastic",
  //       url: `${httpEndpoint}/models/bucket/bucket-plastic.glb`,
  //       // videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`,
  //       videos: [],
  //       desc: "鐵桶主要用來收集和儲存水，也常用於在明火上烹煮食物。它還是一個多用途的容器，用於運輸穀物和清潔工作，成為日常生活中必不可少的工具。",
  //       defaultScale: 1.0, // MARK: 12.0
  //       defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
  //     },
  //   },
  // },
  // {
  //   id: "sewing-machine-with-table",
  //   name: "上一代手動衣車",
  //   entity: {
  //     ancient: {
  //       id: "sewing-machine-with-table",
  //       url: `${httpEndpoint}/models/sewing-machine/sewing-machine-with-table.glb`,
  //       // videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`,
  //       videos: [],
  //       desc: "衣車是一種古老的縫紉工具，主要用於手工縫製衣物和其他布料。操作簡單，依賴人力推動，能夠靈活地進行各種縫紉工作，常見於家庭和小型作坊中。",
  //       defaultScale: 0.5, // MARK: 12.0
  //       defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
  //     },
  //   },
  // },
];

const ARSSPPage = () => {
  const [objPosition, setObjPosition] = useState({ x: 0, y: 0, z: 0 });
  // const [objPosition, setObjPosition] = useState({ x: 100, y: -600, z: 250 });
  const [objScale, setObjScale] = useState(1.0);
  const isPinchingRef = useRef(false);
  const markerRef = useRef(null);
  const meiHoEntityOldRef = useRef(null);
  const meiHoEntityNewRef = useRef(null);
  const currentEntityRef = useRef(null);
  const currentExtraEntitiesRef = useRef([]);
  // const [modelMisc, setModelMisc] = useState({ desc: "" });
  const [modelMisc, setModelMisc] = useState({ videos: [], desc: "" });
  const [currentEntityId, setCurrentEntityId] = useState(null);
  const currentEntityIdRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const isNewRef = useRef(false);
  // const lastTouchRef = useRef(null);
  const lastTouchesRef = useRef([]);
  const rotationRef = useRef({});
  const scaleRef = useRef(1.0);
  const [debugMsg, setDebugMsg] = useState("");
  const [entities, setEntities] = useState([]);
  const entitiesRef = useRef([]);
  const rendererRef = useRef(null);
  const [isTutorial, setIsTutorial] = useState(false);
  const aVideoRef = useRef(null);
  const foundCountRef = useRef(Array(10).fill(0));
  const [cameraDevices, setCameraDevices] = useState([]);
  const [isVerticalRotate, setIsVerticalRotate] = useState(false);
  const isVerticalRotateRef = useRef(false);
  // const [bodyWidthHeight, setBodyWidthHeight] = useState({ width: window.innerWidth, height: window.innerHeight });

  const appSingleton = new AppSingleton();
  const navigate = useNavigate();

  useEffect(() => {
    isVerticalRotateRef.current = isVerticalRotate;
  }, [isVerticalRotate]);

  const screenTouchStart = (evt) => {
    // console.log(`Touch Start`);
    const touches = evt.touches;
    if (touches.length === 2) {
      isPinchingRef.current = true;
    }
    lastTouchesRef.current = touches;
    // updateRef();
  };

  const screenTouchMove = (evt) => {
    // console.log(`Touch Move`);
    const touches = evt.touches;

    if (isCRM) {
      const { x, y, z } = currentEntityRef.current?.getAttribute("rotation");
      // console.log(`x: ${x}\ty: ${y}\tz: ${z}`);
      const touches = evt.touches;
      if (touches.length === 1) {
        const { clientX, clientY } = touches[0];
        // const _rotation = { x, y: y - (clientX - lastTouchesRef.current[0].clientX), z };
        const _rotation = {
          x: isVerticalRotateRef.current ? x - (clientY - lastTouchesRef.current[0].clientY) : x,
          y: y - (clientX - lastTouchesRef.current[0].clientX),
          z,
        };
        currentEntityRef.current?.setAttribute("rotation", _rotation);
        currentExtraEntitiesRef.current?.forEach((el) => {
          el?.setAttribute("rotation", _rotation);
        });
        rotationRef.current = _rotation;
      } else if (touches.length === 2 && lastTouchesRef.current.length === 2) {
        setObjScale(
          calculateDistance(evt.touches[0], evt.touches[1]) /
            calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1])
        );
        scaleRef.current *=
          calculateDistance(evt.touches[0], evt.touches[1]) /
          calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1]);
      }
    } else {
      let { x, y, z } = currentEntityRef.current?.getAttribute("rotation");
      // console.log(`x: ${x}\ty: ${y}\tz: ${z}`);
      if (touches.length === 1) {
        const { clientX, clientY } = touches[0];
        // const _rotation = { x, y: y - (clientX - lastTouchesRef.current[0].clientX), z };
        const _rotation = {
          x: isVerticalRotateRef.current ? x - (clientY - lastTouchesRef.current[0].clientY) : x,
          y: y - (clientX - lastTouchesRef.current[0].clientX),
          z,
        };
        currentEntityRef.current?.setAttribute("rotation", _rotation);
        rotationRef.current = _rotation;
      } else if (touches.length === 2 && lastTouchesRef.current.length === 2) {
        setObjScale(
          calculateDistance(evt.touches[0], evt.touches[1]) /
            calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1])
        );
      }
    }
    lastTouchesRef.current = touches;
    // updateRef();
  };

  const screenTouchEnd = (evt) => {
    if (evt.touches.length < 2) {
      isPinchingRef.current = false;
    }
  };

  const videoLaoded = (evt) => {
    const video = document.querySelector("video");
    // video.width = "1000px";
    // video.height = "2000px";
    video?.addEventListener("playing", function () {
      console.log("Webcam stream started");
      setIsTutorial(true);
    });
  };

  const updateCurrentEntityId = (indexDelta) => {
    const entities = entitiesRef.current;
    const currentIndex = entities.findIndex((d) => d.id === currentEntityId);
    let newIndex = (currentIndex + indexDelta) % entities.length;
    if (newIndex < 0) {
      newIndex += entities.length;
    }
    setCurrentEntityId(entities[newIndex].id);
    currentEntityIdRef.current = entities[newIndex].id;
  };

  useEffect(() => {
    // window.innerWidth = "2340px";
    // window.innerHeight = "1080px";

    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraDevices(cameras);
    });

    const { marker, scene } = addMarker({ url: `${httpEndpoint}/images/pattern-naac-map.patt` });
    markerRef.current = marker;

    if (isVideo) {
      var aVideo = document.createElement("a-video");
      aVideo?.setAttribute("width", `2.8`);
      aVideo?.setAttribute("height", `1.8`);
      aVideo?.setAttribute("position", `0 1 -2`);
      marker.appendChild(aVideo);
      aVideoRef.current = aVideo;
    }

    if (isCRM) {
      const dataListAddedEntity = dataList.reduce((prev, current) => {
        const ancientDict = current?.entity?.ancient;
        const modernDict = current?.entity?.modern;
        if (ancientDict) {
          const { entity: ancientEntity, extraEntities } = addEntityRuntime({ markerRef, data: ancientDict });
          ancientDict.entity = ancientEntity;
          ancientDict.extraEntities = extraEntities;

          // const ancientVideo = document.createElement("video");
          // ancientVideo?.setAttribute("src", `${current?.entity?.ancient?.videoUrl}`);
        }
        if (modernDict) {
          const { entity: modernEntity, extraEntities } = addEntityRuntime({ markerRef, data: modernDict });
          modernDict.entity = modernEntity;
          modernDict.extraEntities = extraEntities;

          // const modernVideo = document.createElement("video");
          // modernVideo?.setAttribute("id", `${current?.entity?.modern?.id}`);
          // modernVideo?.setAttribute("src", `${current?.entity?.modern?.videoUrl}`);
        }

        // if (getDeviceType() === "android") {
        //   setTimeout(() => {
        //     const videoScalingFactor = 0.2;
        //     const videoContainer = document.createElement("div");
        //     const body = document.body;
        //     body.appendChild(videoContainer);
        //     videoContainer.style.position = "absolute";
        //     // videoContainer.style.left = "-864px";
        //     // videoContainer.style.top = "-1064px";
        //     videoContainer.style.left = `-${(window.innerWidth - (window.innerWidth * videoScalingFactor)) / 2}px`;
        //     videoContainer.style.top = `-${(window.innerHeight - (window.innerHeight * videoScalingFactor)) / 2}px`;

        //     const arjsVideo = document.getElementById("arjs-video");
        //     console.log(arjsVideo);
        //     arjsVideo.style.transform = "scale(0.2)";
        //     videoContainer.appendChild(arjsVideo);
        //   }, 3000);
        // }

        return [...prev, current];
      }, []);

      entitiesRef.current = dataListAddedEntity;
      setEntities(dataListAddedEntity);
      setCurrentEntityId(dataListAddedEntity[0].id);
      currentEntityIdRef.current = dataListAddedEntity[0].id;
    } else {
      addEntity({
        markerRef: markerRef,
        entityRef: meiHoEntityOldRef,
        url: "https://survey.reality-connect.tech/models/mei-ho-house/Ancient-c.glb",
        defaultScale: 0.04,
      });
      addEntity({
        markerRef: markerRef,
        entityRef: meiHoEntityNewRef,
        url: "https://survey.reality-connect.tech/models/mei-ho-house/Modern-c.glb",
        defaultScale: 0.04,
      });
    }
    // <a-animation attribute="rotation" to="360 0 0" dur="5000" easing="linear" repeat="indefinite" />

    const renderer = rendererRef.current;
    if (renderer) {
      renderer.componentWillUnmount = () => {};
    }
    // renderer.setPixelRatio(window.devicePixelRatio);
    const videoTag = renderer?.container.children["arjs-video"];

    window.addEventListener("arjs-video-loaded", videoLaoded);
    window.addEventListener("touchstart", screenTouchStart);
    window.addEventListener("touchmove", screenTouchMove);
    window.addEventListener("touchend", screenTouchEnd);

    // window.addEventListener("load", () => {
    //   console.log("load");
    //   const observer = new ResizeObserver((entries) => {
    //     for (const entry of entries) {
    //       if (entry.target === document.body) {
    //         // const bodyWidth = entry.contentRect.width;
    //         // const bodyHeight = entry.contentRect.height;

    //         const bodyWidth = window.innerWidth || document.documentElement.clientWidth || document.body.clientWidth;
    //         const bodyHeight = window.innerHeight || document.documentElement.clientHeight || document.body.clientHeight;

    //         // const scene = document.querySelector("a-scene");
    //         // scene?.setAttribute("width", bodyWidth);
    //         // scene?.setAttribute("height", bodyHeight);

    //         // setBodyWidthHeight({ width: bodyWidth, height: bodyHeight });

    //         console.log("Body width changed:", bodyWidth);
    //         console.log("Body height changed:", bodyHeight);
    //       }
    //     }
    //   });

    //   observer.observe(document.body);
    // });

    marker.addEventListener("foundCount", (e) => {
      const _foundCount = e.detail.value ?? 0;
      // console.log(e.detail.value);
      let _a = foundCountRef.current;
      _a.shift();
      _a.push(_foundCount);
      if (_a.every((element) => element === _a[0])) {
        // console.log("invisible");
        entitiesRef.current.forEach(({ id, name, entity }) => {
          entity?.ancient?.entity?.setAttribute("visible", false);
          entity?.ancient?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
          entity?.modern?.entity?.setAttribute("visible", false);
          entity?.modern?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
        });
      } else {
        // console.log("visible");
        entitiesRef.current.forEach(({ id, name, entity }) => {
          const ancientVisible = id.includes(currentEntityIdRef.current) && !isNewRef.current;
          if (entity?.ancient?.entity?.visible !== ancientVisible) {
            entity?.ancient?.entity?.setAttribute("visible", ancientVisible);
            entity?.ancient?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", ancientVisible);
            });
          }
          const modernVisible = id.includes(currentEntityIdRef.current) && isNewRef.current;
          if (entity?.modern?.entity?.visible !== modernVisible) {
            entity?.modern?.entity?.setAttribute("visible", modernVisible);
            entity?.modern?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", modernVisible);
            });
          }
        });
      }
      foundCountRef.current = _a;
      setIsTutorial(false);
    });

    // document.querySelectorAll("a-entity").forEach((entity) => {
    //   if (entity.components["look-controls"]) {
    //     entity.components["look-controls"].enabled = false;
    //     entity.removeAttribute("look-controls");
    //   }
    // });
    return () => {
      window.removeEventListener("arjs-video-loaded", videoLaoded);
      window.removeEventListener("touchstart", screenTouchStart);
      window.removeEventListener("touchmove", screenTouchMove);
      window.removeEventListener("touchend", screenTouchEnd);
      window.removeEventListener("foundCount");
      window.removeEventListener("markerLost");
      const _videoTag = videoTag ?? renderer.container.children["arjs-video"];
      if (_videoTag) {
        _videoTag.parentNode?.removeChild(_videoTag);
      }
      disposeAllEntities();
    };
  }, []);

  useEffect(() => {
    if (markerRef.current) {
      const childCount = markerRef.current.childElementCount;
      if (isCRM) {
        entitiesRef.current.forEach(({ id, name, entity }) => {
          entity?.ancient?.entity?.setAttribute("visible", id === currentEntityId && !isNew);
          entity?.modern?.entity?.setAttribute("visible", id === currentEntityId && isNew);
        });
        const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
        if (_entities.length > 0) {
          const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
          if (_entityDict) {
            _entityDict.entity?.setAttribute("rotation", rotationRef.current);
            // const { x, y, z } = _entity.getAttribute("scale");
            _entityDict.entity?.setAttribute(
              "scale",
              `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
                _entityDict.defaultScale * scaleRef.current
              }`
            );
            // MARK: Rotate and scale extras entities when visible
            _entityDict.extraEntities?.forEach((e) => {
              e?.setAttribute("rotation", rotationRef.current);
              // e?.setAttribute(
              //   "scale",
              //   `${e.defaultScale * scaleRef.current} ${e.defaultScale * scaleRef.current} ${e.defaultScale * scaleRef.current}`
              // );
            });
            currentEntityRef.current = _entityDict.entity;
            currentExtraEntitiesRef.current = _entityDict.extraEntities;
            // MARK: Update Description
            setModelMisc({ desc: _entityDict.desc, videos: _entityDict.videos ?? "" });
            // setModelDescription(_entityDict.desc);

            // MARK: update a-video src by currentEntityId
            if (isVideo) {
              // const aVideo = document.querySelector("a-video").components?.material?.material?.map;
              // const aAudio = aVideo?.map?.audio;
              // aAudio?.pause();

              aVideoRef.current.pause();
              aVideoRef.current.currentTime = 0;
              // aVideoRef.current?.setAttribute("src", _entityDict?.videoUrl);
              aVideoRef.current?.setAttribute("src", `#${_entityDict.id}`);
              aVideoRef.current.play();
              // aVideoRef.current.needsUpdate = true;
            }
          }
        }
      } else {
        meiHoEntityNewRef.current?.setAttribute("visible", "false");
        meiHoEntityOldRef.current?.setAttribute("visible", "false");
        if (isNew) {
          meiHoEntityNewRef.current?.setAttribute("visible", "true");
          currentEntityRef.current = meiHoEntityNewRef.current;
          // markerRef.current.appendChild(meiHoEntityNewRef.current);
        } else {
          meiHoEntityOldRef.current?.setAttribute("visible", "true");
          currentEntityRef.current = meiHoEntityOldRef.current;
          // markerRef.current.appendChild(meiHoEntityOldRef.current);
        }
        const { x, y, z } = rotationRef.current;
        currentEntityRef.current?.setAttribute("rotation", { x, y, z });
        currentExtraEntitiesRef.current?.forEach((el) => {
          el?.setAttribute("rotation", { x, y, z });
        });
      }
    }
  }, [currentEntityId, isNew]);

  useEffect(() => {
    currentEntityRef.current?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    currentExtraEntitiesRef.current?.forEach((el) => {
      el?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    });
  }, [objPosition]);

  useEffect(() => {
    // console.log("objScale");
    if (isCRM) {
      const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
      if (_entities.length > 0) {
        // setDebugMsg(`scale: ${x} ${y} ${z}`);
        const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
        if (_entityDict) {
          _entityDict.entity?.setAttribute(
            "scale",
            `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
              _entityDict.defaultScale * scaleRef.current
            }`
          );
        }
        // currentEntityRef.current?.setAttribute("scale", `${x * objScale} ${y * objScale} ${z * objScale}`);
      }
    } else {
      const { x, y, z } = currentEntityRef.current?.getAttribute("scale");
      // setDebugMsg(`scale: ${x} ${y} ${z}`);
      currentEntityRef.current?.setAttribute("scale", `${x * objScale} ${y * objScale} ${z * objScale}`);
      currentExtraEntitiesRef.current?.forEach((el) => {
        const { x, y, z } = el?.getAttribute("scale");
        el?.setAttribute("scale", `${x * objScale} ${y * objScale} ${z * objScale}`);
      });
    }
  }, [objScale, isNew]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {isTutorial ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
            overflow: "hidden",
          }}
        >
          <img
            id="paper"
            // src="https://survey.reality-connect.tech/images/paper.png"
            src="/images/paper.png"
            alt="paper"
            style={{
              position: "absolute",
              width: 600,
              height: 300,
            }}
          />
          <img
            id="hold-and-scan"
            // src="https://survey.reality-connect.tech/images/hold-to-scan.png"
            src="/images/hold-to-scan.png"
            alt="Hold And Scan"
            style={{
              position: "absolute",
              width: 300,
              height: 300,
              animation: "moveLeftRight 5s ease-in-out infinite",
            }}
          />
        </Box>
      ) : null}
      <Switch
        sx={{ position: "absolute", zIndex: 999, right: 16, top: 16 }}
        checked={isVerticalRotate}
        onChange={(evt) => setIsVerticalRotate(evt.target.checked)}
      />
      <IntroductoryAvatar
        sx={{
          position: "absolute",
          bottom: 128,
          right: 16,
        }}
        {...modelMisc}
      />
      <Fab
        color={isNew ? "primary" : "#AAAAAA"}
        aria-label="Furnish"
        sx={{
          position: "absolute",
          right: 16,
          bottom: 64,
          zIndex: 999,
          backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          "&:hover": {
            backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          },
        }}
        onClick={() => {
          setIsNew((_prev) => {
            isNewRef.current = !_prev;
            return !_prev;
          });
        }}
      >
        <ColorLens />
      </Fab>

      <Grid
        container
        justifyContent="space-between"
        sx={{ position: "absolute", top: 32, right: 0, left: 0, display: "flex", width: "100%", zIndex: 999 }}
      >
        <Fab
          aria-label="back"
          sx={{
            ml: 4,
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            color: "#FFF",
            "&:hover": {
              backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            },
          }}
          onClick={() => {
            window.location.href = backendEndPoint;
          }}
        >
          <ArrowBackIosNew />
        </Fab>
        <Box
          sx={{
            borderRadius: 16,
            backgroundColor: appSingleton.themeColor.foreColor.main,
            display: "flex",
            justifyContent: "center",
            pl: 4,
            pr: 4,
            // pt: 1,
            // pb: 1,
          }}
        >
          <Typography sx={{ fontSize: { xs: 18, sm: 24, md: 36 }, color: "#FFF", display: "flex", alignItems: "center" }}>
            {entities.filter((el) => el.id === currentEntityId).length > 0
              ? entities.filter((el) => el.id === currentEntityId)[0].name
              : "No Name"}
          </Typography>
        </Box>
        <Box sx={{ width: "56px", height: "56px", mr: 4 }}></Box>
      </Grid>
      <Button
        variant="contained"
        startIcon={<ArrowBackIosNew />}
        sx={{
          position: "absolute",
          left: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(-1);
        }}
      >
        上一個回憶
      </Button>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIos />}
        sx={{
          position: "absolute",
          right: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(1);
        }}
      >
        下一個回憶
      </Button>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#F5F5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: -999,
          overflow: "hidden",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            軟件需要相機權限，
          </Typography>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            才能為您呈現擴增實境元素。
          </Typography>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: 0, zIndex: 9999, backgroundColor: "white" }}>
        <Typography> {`${debugMsg}`}</Typography>

        {/* {cameraDevices.map((device) => (
          <li key={device.deviceId}>{device.label}</li>
        ))} */}
      </Box>

      <AFrameRenderer
        // stats
        arToolKit={{
          sourceType: "webcam",
          tangoPointCloudEnabled: true,
          sourceWidth: 414,
          // sourceHeight: appSingleton.windowSize.height,
          // displayWidth: window.innerWidth / 4,
          // displayHeight: window.innerHeight / 4,
          // sourceWidth: 2340,
          // sourceHeight: 1080,
          displayWidth: 414,
          // displayWidth: 480,
          // displayHeight: 640,
          // displayWidth: window.innerWidth * 0.8,
          // displayHeight: window.innerHeight * 0.9,
          canvasWidth: 414,
          // canvasWidth: window.innerWidth * 0.8,
          // canvasHeight: window.innerHeight * 0.9,
        }}
        renderer="logarithmicDepthBuffer: true; precision: medium;"
        ref={rendererRef}
      ></AFrameRenderer>

      {/* <Helmet>
        <script>
          {`
          AFRAME.registerComponent('sphere-click', {
              init: function() {
              this.el.addEventListener('click', e => {
                  console.log('sphere clicked!');
              });
              }
          });
          `}
        </script>
      </Helmet> */}
    </Box>
  );
};

const f = () => {};

export { ARSSPPage, f };
