import React, { useState, useEffect, useRef } from "react";
import { useNavigate } from "react-router-dom";
import { Box, Button, Fab, Grid, Icon, IconButton, Switch, Typography, TextField } from "@mui/material";
import { ColorLens, ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { AFrameRenderer } from "./AFrameRenderer.js";
import { calculateDistance, getDeviceType, getRoute } from "../utils/CommonUtils";
import { getModelUrl } from "../utils/ModelUtils";
import { backendEndPoint, httpEndpoint, isDebug } from "../services/backend";
import { IntroductoryAvatar } from "../components/IntroductoryAvatar";
import { AppSingleton } from "../services/user_config";
import "./ar-tm-page.css";

const isVideo = false;

const addMarker = ({ url, key }) => {
  const scene = document.querySelector("a-scene");

  scene?.setAttribute("embedded", "");
  scene?.setAttribute("xr-mode-ui", "enabled: false");
  scene?.setAttribute("arjs", "trackingMethod: best;");

  var marker = document.createElement("a-marker-camera");
  marker?.setAttribute("type", "pattern");
  marker?.setAttribute("preset", "custom");
  marker?.setAttribute("emitevents", true);
  marker?.setAttribute("size", 45);
  marker?.setAttribute("smoothCount", 20);
  if (url) {
    marker?.setAttribute("url", url);
  }
  if (key) {
    marker?.setAttribute("url", getModelUrl({ key: key }));
  }
  scene?.appendChild(marker);

  // MARK: Add cursor to allow for clicking and basic interactivity with a scene on devices
  // const cursor = document.createElement("a-cursor");
  // marker.appendChild(cursor);

  // MARK: a-assets to preload if needed
  const assets = document.createElement("a-assets");
  scene?.appendChild(assets);

  return { marker, scene };
};

const addEntityRuntime = ({ markerRef, data }) => {
  var entity = document.createElement("a-entity");
  let extraEntities = [];
  if (data) {
    entity?.setAttribute("gltf-model", `url(${data?.url})`);
    entity?.setAttribute("scale", `${data?.defaultScale} ${data?.defaultScale} ${data?.defaultScale}`);
    entity?.setAttribute("position", `${data?.defaultPosition.x} ${data?.defaultPosition.y} ${data?.defaultPosition.z}`);
    entity?.setAttribute("rotation", `1 1 1`);
    entity?.setAttribute("z-index", "9999");
    entity?.setAttribute("anchored", "persistent: true");
    markerRef.current.appendChild(entity);
    entity?.setAttribute("visible", "false");

    data?.extras?.forEach((_extra) => {
      if (_extra?.type.toLowerCase() === "ball-blue-small") {
        const aSphere = document.createElement("a-sphere");
        // aSphere?.setAttribute("anchored", "persistent: true");
        aSphere?.setAttribute("color", "blue");
        aSphere?.setAttribute("radius", "1");
        aSphere?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        aSphere?.setAttribute("class", "sphere");
        aSphere?.setAttribute("sphere-click", "");

        aSphere?.setAttribute(
          "position",
          `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`
        );
        aSphere?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(aSphere);
        aSphere?.setAttribute("visible", "false");
        extraEntities.push(aSphere);
      } else if (_extra?.type.toLowerCase() === "box-red-small") {
        const box = document.createElement("a-box");
        // box?.setAttribute("anchored", "persistent: true");
        box?.setAttribute("color", "red");
        box?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        box?.setAttribute("class", "box");
        box?.setAttribute("box-click", "");
        box?.setAttribute("position", `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`);
        box?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(box);
        box?.setAttribute("visible", "false");
        extraEntities.push(box);
      }
    });
  }
  return { entity, extraEntities };
};

const disposeAllEntities = () => {
  // console.log("disposeAllEntities");
  const entities = document.querySelectorAll("a-entity");
  // const markers = document.querySelectorAll("a-marker");
  const markers = document.querySelectorAll("a-marker-camera");
  entities?.forEach((entity) => {
    entity.object3DMap.mesh.dispose();
    entity.parentNode.removeChild(entity);
  });

  markers?.forEach((marker) => {
    marker.parentNode.removeChild(marker);
  });
};

const dataList = [
  {
    id: "poloshan",
    name: "屯門大峽谷 - 菠蘿山",
    entity: {
      ancient: {
        id: "poloshan-ancient",
        url: `${httpEndpoint}/models/poloshan/Ancient-c.glb`,
        videos: [
          { name: "菠蘿山 - 1", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#FFAC98" },
          { name: "菠蘿山 - 2", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#D0F0C0" },
          { name: "菠蘿山 - 3", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#B5E2FF" },
        ],
        desc: "菠蘿山，又被稱為「香港的大峽谷」，位於良田坳峽谷，是屯門區的一座小山丘，海拔高達121米。它座落於山景邨和良景邨之間，每年吸引了許多香港人和遊客前來遠足、郊遊和拍照留念。菠蘿山是香港重要的本土文化和歷史景點，承載著豐富的歷史內涵和文化價值。過去，這個地區是屯門地區的農田和果園，而現在則成為人們休閒娛樂的場所。透過虛擬實景技術，我們可以透過手機更深入地了解菠蘿山地貌的獨特之處。",
        defaultScale: 1.0, // MARK: 12.0
        defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
      },
    },
  },
];

const ARTMPage = () => {
  const [objPosition, setObjPosition] = useState({ x: 0, y: 0, z: 0 });
  const [objScale, setObjScale] = useState(1.0);
  const isPinchingRef = useRef(false);
  const markerRef = useRef(null);
  const currentEntityRef = useRef(null);
  const currentExtraEntitiesRef = useRef([]);
  const [modelMisc, setModelMisc] = useState({ videos: [], desc: "" });
  const [currentEntityId, setCurrentEntityId] = useState(null);
  const currentEntityIdRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const isNewRef = useRef(false);
  const lastTouchesRef = useRef([]);
  const rotationRef = useRef({});
  const scaleRef = useRef(1.0);
  const [debugMsg, setDebugMsg] = useState("");
  const [entities, setEntities] = useState([]);
  const entitiesRef = useRef([]);
  const rendererRef = useRef(null);
  const [isTutorial, setIsTutorial] = useState(false);
  const aVideoRef = useRef(null);
  const foundCountRef = useRef(Array(10).fill(0));
  const [cameraDevices, setCameraDevices] = useState([]);
  const [isVerticalRotate, setIsVerticalRotate] = useState(false);
  const isVerticalRotateRef = useRef(false);
  // const [bodyWidthHeight, setBodyWidthHeight] = useState({ width: window.innerWidth, height: window.innerHeight });

  const appSingleton = new AppSingleton();
  const navigate = useNavigate();

  useEffect(() => {
    isVerticalRotateRef.current = isVerticalRotate;
  }, [isVerticalRotate]);

  const screenTouchStart = (evt) => {
    const touches = evt.touches;
    if (touches.length === 2) {
      isPinchingRef.current = true;
    }
    lastTouchesRef.current = touches;
  };

  const screenTouchMove = (evt) => {
    const touches = evt.touches;
    const { x, y, z } = currentEntityRef.current?.getAttribute("rotation");
    if (touches.length === 1) {
      const { clientX, clientY } = touches[0];
      // const _rotation = { x, y: y - (clientX - lastTouchesRef.current[0].clientX), z };
      const _rotation = {
        x: isVerticalRotateRef.current ? x - (clientY - lastTouchesRef.current[0].clientY) : x,
        y: y - (clientX - lastTouchesRef.current[0].clientX),
        z,
      };
      currentEntityRef.current?.setAttribute("rotation", _rotation);
      currentExtraEntitiesRef.current?.forEach((el) => {
        el?.setAttribute("rotation", _rotation);
      });
      rotationRef.current = _rotation;
    } else if (touches.length === 2 && lastTouchesRef.current.length === 2) {
      setObjScale(
        calculateDistance(evt.touches[0], evt.touches[1]) /
          calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1])
      );
      scaleRef.current *=
        calculateDistance(evt.touches[0], evt.touches[1]) /
        calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1]);
    }
    lastTouchesRef.current = touches;
  };

  const screenTouchEnd = (evt) => {
    if (evt.touches.length < 2) {
      isPinchingRef.current = false;
    }
  };

  const videoLaoded = (evt) => {
    const video = document.querySelector("video");
    video?.addEventListener("playing", function () {
      console.log("Webcam stream started");
      setIsTutorial(true);
    });
  };

  const updateCurrentEntityId = (indexDelta) => {
    const entities = entitiesRef.current;
    const currentIndex = entities.findIndex((d) => d.id === currentEntityId);
    let newIndex = (currentIndex + indexDelta) % entities.length;
    if (newIndex < 0) {
      newIndex += entities.length;
    }
    setCurrentEntityId(entities[newIndex].id);
    currentEntityIdRef.current = entities[newIndex].id;
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraDevices(cameras);
    });

    const { marker, scene } = addMarker({ url: `${httpEndpoint}/images/pattern-naac-map.patt` });
    markerRef.current = marker;

    if (isVideo) {
      var aVideo = document.createElement("a-video");
      aVideo?.setAttribute("width", `2.8`);
      aVideo?.setAttribute("height", `1.8`);
      aVideo?.setAttribute("position", `0 1 -2`);
      marker.appendChild(aVideo);
      aVideoRef.current = aVideo;
    }

    const dataListAddedEntity = dataList.reduce((prev, current) => {
      const ancientDict = current?.entity?.ancient;
      const modernDict = current?.entity?.modern;
      if (ancientDict) {
        const { entity: ancientEntity, extraEntities } = addEntityRuntime({ markerRef, data: ancientDict });
        ancientDict.entity = ancientEntity;
        ancientDict.extraEntities = extraEntities;
      }
      if (modernDict) {
        const { entity: modernEntity, extraEntities } = addEntityRuntime({ markerRef, data: modernDict });
        modernDict.entity = modernEntity;
        modernDict.extraEntities = extraEntities;
      }

      return [...prev, current];
    }, []);

    entitiesRef.current = dataListAddedEntity;
    setEntities(dataListAddedEntity);
    setCurrentEntityId(dataListAddedEntity[0].id);
    currentEntityIdRef.current = dataListAddedEntity[0].id;

    const renderer = rendererRef.current;
    if (renderer) {
      renderer.componentWillUnmount = () => {};
    }
    const videoTag = renderer?.container.children["arjs-video"];

    window.addEventListener("arjs-video-loaded", videoLaoded);
    window.addEventListener("touchstart", screenTouchStart);
    window.addEventListener("touchmove", screenTouchMove);
    window.addEventListener("touchend", screenTouchEnd);

    marker.addEventListener("foundCount", (e) => {
      const _foundCount = e.detail.value ?? 0;
      let _a = foundCountRef.current;
      _a.shift();
      _a.push(_foundCount);
      if (_a.every((element) => element === _a[0])) {
        entitiesRef.current.forEach(({ id, name, entity }) => {
          entity?.ancient?.entity?.setAttribute("visible", false);
          entity?.ancient?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
          entity?.modern?.entity?.setAttribute("visible", false);
          entity?.modern?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
        });
      } else {
        entitiesRef.current.forEach(({ id, name, entity }) => {
          const ancientVisible = id.includes(currentEntityIdRef.current) && !isNewRef.current;
          if (entity?.ancient?.entity?.visible !== ancientVisible) {
            entity?.ancient?.entity?.setAttribute("visible", ancientVisible);
            entity?.ancient?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", ancientVisible);
            });
          }
          const modernVisible = id.includes(currentEntityIdRef.current) && isNewRef.current;
          if (entity?.modern?.entity?.visible !== modernVisible) {
            entity?.modern?.entity?.setAttribute("visible", modernVisible);
            entity?.modern?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", modernVisible);
            });
          }
        });
      }
      foundCountRef.current = _a;
      setIsTutorial(false);
    });

    return () => {
      window.removeEventListener("arjs-video-loaded", videoLaoded);
      window.removeEventListener("touchstart", screenTouchStart);
      window.removeEventListener("touchmove", screenTouchMove);
      window.removeEventListener("touchend", screenTouchEnd);
      window.removeEventListener("foundCount");
      window.removeEventListener("markerLost");
      const _videoTag = videoTag ?? renderer.container.children["arjs-video"];
      if (_videoTag) {
        _videoTag.parentNode?.removeChild(_videoTag);
      }
      disposeAllEntities();
    };
  }, []);

  useEffect(() => {
    if (markerRef.current) {
      const childCount = markerRef.current.childElementCount;
      entitiesRef.current.forEach(({ id, name, entity }) => {
        entity?.ancient?.entity?.setAttribute("visible", id === currentEntityId && !isNew);
        entity?.modern?.entity?.setAttribute("visible", id === currentEntityId && isNew);
      });
      const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
      if (_entities.length > 0) {
        const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
        if (_entityDict) {
          _entityDict.entity?.setAttribute("rotation", rotationRef.current);
          // const { x, y, z } = _entity.getAttribute("scale");
          _entityDict.entity?.setAttribute(
            "scale",
            `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
              _entityDict.defaultScale * scaleRef.current
            }`
          );
          // MARK: Rotate and scale extras entities when visible
          _entityDict.extraEntities?.forEach((e) => {
            e?.setAttribute("rotation", rotationRef.current);
          });
          currentEntityRef.current = _entityDict.entity;
          currentExtraEntitiesRef.current = _entityDict.extraEntities;
          // MARK: Update Description
          setModelMisc({ desc: _entityDict.desc, videos: _entityDict.videos ?? "" });

          // MARK: update a-video src by currentEntityId
          if (isVideo) {
            aVideoRef.current.pause();
            aVideoRef.current.currentTime = 0;
            aVideoRef.current?.setAttribute("src", `#${_entityDict.id}`);
            aVideoRef.current.play();
          }
        }
      }
    }
  }, [currentEntityId, isNew]);

  useEffect(() => {
    currentEntityRef.current?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    currentExtraEntitiesRef.current?.forEach((el) => {
      el?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    });
  }, [objPosition]);

  useEffect(() => {
    const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
    if (_entities.length > 0) {
      // setDebugMsg(`scale: ${x} ${y} ${z}`);
      const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
      if (_entityDict) {
        _entityDict.entity?.setAttribute(
          "scale",
          `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
            _entityDict.defaultScale * scaleRef.current
          }`
        );
      }
    }
  }, [objScale, isNew]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {isTutorial ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
            overflow: "hidden",
          }}
        >
          <img
            id="paper"
            src="/images/paper.png"
            alt="paper"
            style={{
              position: "absolute",
              width: 600,
              height: 300,
            }}
          />
          <img
            id="hold-and-scan"
            src="/images/hold-to-scan.png"
            alt="Hold And Scan"
            style={{
              position: "absolute",
              width: 300,
              height: 300,
              animation: "moveLeftRight 5s ease-in-out infinite",
            }}
          />
        </Box>
      ) : null}
      <Switch
        sx={{ position: "absolute", zIndex: 999, right: 16, top: 16 }}
        checked={isVerticalRotate}
        onChange={(evt) => setIsVerticalRotate(evt.target.checked)}
      />
      <IntroductoryAvatar
        sx={{
          position: "absolute",
          bottom: 128,
          right: 16,
        }}
        {...modelMisc}
      />
      <Fab
        color={isNew ? "primary" : "#AAAAAA"}
        aria-label="Furnish"
        sx={{
          position: "absolute",
          right: 16,
          bottom: 64,
          zIndex: 999,
          backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          "&:hover": {
            backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          },
        }}
        onClick={() => {
          setIsNew((_prev) => {
            isNewRef.current = !_prev;
            return !_prev;
          });
        }}
      >
        <ColorLens />
      </Fab>

      <Grid
        container
        justifyContent="space-between"
        sx={{ position: "absolute", top: 32, right: 0, left: 0, display: "flex", width: "100%", zIndex: 999 }}
      >
        <Fab
          aria-label="back"
          sx={{
            ml: 4,
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            color: "#FFF",
            "&:hover": {
              backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            },
          }}
          onClick={() => {
            window.location.href = backendEndPoint;
          }}
        >
          <ArrowBackIosNew />
        </Fab>
        <Box
          sx={{
            borderRadius: 16,
            backgroundColor: appSingleton.themeColor.foreColor.main,
            display: "flex",
            justifyContent: "center",
            pl: 4,
            pr: 4,
          }}
        >
          <Typography sx={{ fontSize: { xs: 18, sm: 24, md: 36 }, color: "#FFF", display: "flex", alignItems: "center" }}>
            {entities.filter((el) => el.id === currentEntityId).length > 0
              ? entities.filter((el) => el.id === currentEntityId)[0].name
              : "No Name"}
          </Typography>
        </Box>
        <Box sx={{ width: "56px", height: "56px", mr: 4 }}></Box>
      </Grid>
      <Button
        variant="contained"
        startIcon={<ArrowBackIosNew />}
        sx={{
          position: "absolute",
          left: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(-1);
        }}
      >
        上一個回憶
      </Button>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIos />}
        sx={{
          position: "absolute",
          right: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(1);
        }}
      >
        下一個回憶
      </Button>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#F5F5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: -999,
          overflow: "hidden",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            軟件需要相機權限，
          </Typography>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            才能為您呈現擴增實境元素。
          </Typography>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: 0, zIndex: 9999, backgroundColor: "white" }}>
        <Typography> {`${debugMsg}`}</Typography>
      </Box>

      <AFrameRenderer
        arToolKit={{
          sourceType: "webcam",
          tangoPointCloudEnabled: true,
          sourceWidth: 414,
          displayWidth: 414,
          canvasWidth: 414,
        }}
        renderer="logarithmicDepthBuffer: true; precision: medium;"
        ref={rendererRef}
      />
    </Box>
  );
};

const f = () => {};

export { ARTMPage, f };
