import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import {
  Box,
  Button,
  Card,
  CardContent,
  Fab,
  Dialog,
  DialogContent,
  Grid,
  Icon,
  IconButton,
  Switch,
  Typography,
  TextField,
  Divider,
} from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos, Home } from "@mui/icons-material";
import { Canvas } from "@react-three/fiber";
import { backendEndPoint, httpEndpoint, isDebug } from "../services/backend";
import { OrbitControls, Html, useGLTF } from "@react-three/drei";
import { MODEL_ARS, MODEL_PREVIEWS } from "../data/ar-data";
import { ModelPreview } from "../components/ModelPreview";
import { AppSingleton } from "../services/user_config";
import { calculateDistance, getDeviceType, getRoute } from "../utils/CommonUtils";

// const getARDesc = ({ id, key }) => {
//   let _desc = ". . .";
//   if (id && key) {
//     const _dictKey = key.split("-").pop();
//     const results = MODEL_ARS.filter((_ar) => _ar.id === id && _ar.entity[_dictKey].id === key);
//     if (results.length > 0) {
//       _desc = results[0].entity[_dictKey].desc;
//     }
//   }
//   return _desc;
// };

const ListingPage = () => {
  const { region } = useParams();
  const appSingleton = new AppSingleton();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [currentIndex, setCurrentIndex] = useState(0);
  const [glbDict, setGlbDict] = useState(null);
  const [deviceType, setDeviceType] = useState(getDeviceType());

  useEffect(() => {
    setGlbDict(null);
    setTimeout(() => {
      const _modelDicts = MODEL_PREVIEWS.filter((m) => m.region === region);
      const _index = Math.abs(currentIndex % _modelDicts.length);
      const _glbDict = _modelDicts[_index];
      if (_glbDict) {
        setGlbDict(_glbDict);
      }
    }, 500);
  }, [currentIndex, region]);

  return (
    <Box sx={{ width: "100%", height: "100vh", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "20vh",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 5,
          backgroundColor: appSingleton.themeColor.foreColor.main,
        }}
      >
        <Fab
          sx={{
            position: "absolute",
            top: 8,
            left: 8,
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            color: "#FFF",
            "&:hover": {
              backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            },
          }}
          onClick={() => {
            window.location.href = backendEndPoint;
          }}
        >
          <Home />
        </Fab>
        <Box>
          <Typography sx={{ fontSize: 36, color: "#FFF", textAlign: "center" }}>歡迎來到深水埗</Typography>
          <Typography sx={{ fontSize: 24, color: "#FFF", textAlign: "center" }}>{glbDict?.name ?? ""}</Typography>
          <Divider sx={{ ml: 6, mr: 6, mt: 2, mb: 2 }} color="#FFF" />
        </Box>
        <Box sx={{ width: "50%", display: "flex", justifyContent: "space-around" }}>
          {/* {deviceType === "ios" ? (
            <Button
              variant="contained"
              size="large"
              sx={{
                borderRadius: "24px",
                boxShadow: 2,
                // backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
                // color: "#FFF",
                // "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
              }}
            >
              Immersive
            </Button>
          ) : null} */}

          <Button
            variant="contained"
            size="large"
            sx={{
              borderRadius: "24px",
              boxShadow: 2,
              // backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
              // color: "#FFF",
              // "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
            }}
            onClick={() => {
              navigate(`${getRoute("ar-page")}/${region}/${glbDict.id}`);
            }}
          >
            AR Card
          </Button>
        </Box>
      </Box>
      <Fab
        sx={{
          position: "absolute",
          top: "50%",
          left: 8,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
        }}
        onClick={() => setCurrentIndex((prev) => prev - 1)}
      >
        <ArrowBackIosNew />
      </Fab>
      <Fab
        sx={{
          position: "absolute",
          top: "50%",
          right: 8,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": { backgroundColor: `${appSingleton.themeColor.foreColor.main}` },
        }}
        onClick={() => setCurrentIndex((prev) => prev + 1)}
      >
        <ArrowForwardIos />
      </Fab>
      <Canvas ref={canvasRef} style={{ width: "100%", height: "80vh", top: "20vh" }}>
        <ambientLight intensity={2.0} />
        <pointLight position={[2, 2, 0]} intensity={100} decay={2} distance={30} />
        {glbDict ? <ModelPreview glbDict={glbDict} canvasRef={canvasRef} fmPage="listing" /> : null}
        <OrbitControls />
      </Canvas>

      <Card
        sx={{
          position: "absolute",
          bottom: 32,
          width: "90%",
          left: "50%",
          transform: "translateX(-50%)",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: 5,
        }}
      >
        <Typography sx={{ fontSize: 16, m: 4 }}>
          {glbDict?.desc}
          {/* {getARDesc({ id: glbDict?.id, key: glbDict?.key })} */}
        </Typography>
      </Card>
    </Box>
  );
};

const f = () => {};

export { ListingPage, f };
