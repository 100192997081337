import { io } from "socket.io-client";
import { wsEndpoint } from "./backend";

function AppSingleton() {
  if (typeof AppSingleton.cache === "object") {
    return AppSingleton.cache;
  }
  this.navigate = null;
  // MARK: notifications & setNotifications will be set from the App.js
  this.prepareCallbackDict = {};
  this.windowSize = {};
  this.themeColor = {
    foreColor: {
      main: "#0C5440",
    },
  };
  // try {
  //   this.socket = io(wsEndpoint, {
  //     transports: ["polling"],
  //     autoConnect: true,
  //   });
  //   console.log("Socket exists");

  //   this.socket.on("connect", () => {
  //     console.log("Connected to server");
  //   });
  //   this.socket.on("disconnect", () => {
  //     console.log("Disconnected from server");
  //   });
  //   this.socket.on("reconnect", () => {
  //     console.log("Reconnected to server");
  //   });
  //   this.socket.on("message", (message) => {
  //     console.log("message received");
  //     console.log(message);
  //     this.setNotifications([message]);
  //     if (message.status === "prepare_scope") {
  //       Object.values(this.prepareCallbackDict).forEach((func) => {
  //         func(message.data);
  //       });
  //     }
  //   });
  //   console.log("Socket created");

  //   this.socket.connect();
  // } catch {
  //   console.log("!!! Socket Implementation Failed.");
  // }

  AppSingleton.cache = this;
}

export { AppSingleton };
