import React, { useState, useEffect, useRef } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Box, Button, Fab, Dialog, DialogContent, Grid, Icon, IconButton, Switch, Typography, TextField } from "@mui/material";
import { ColorLens, ArrowBackIosNew, ArrowForwardIos, ThreeDRotation, ViewInAr } from "@mui/icons-material";
import { AFrameRenderer } from "./AFrameRenderer.js";
import { calculateDistance, getDeviceType, getRoute } from "../utils/CommonUtils";
import { getModelUrl } from "../utils/ModelUtils";
import { backendEndPoint, httpEndpoint, isDebug } from "../services/backend";
import { IntroductoryAvatar } from "../components/IntroductoryAvatar";
import { AppSingleton } from "../services/user_config";
import { Canvas } from "@react-three/fiber";
import { OrbitControls, Html, useGLTF } from "@react-three/drei";
import { ModelPreview } from "../components/ModelPreview";
import "./ar-page.css";
import { MODEL_ARS, MODEL_PREVIEWS } from "../data/ar-data";

const isVideo = false;

const addMarker = ({ url, key }) => {
  const scene = document.querySelector("a-scene");

  scene?.setAttribute("embedded", "");
  scene?.setAttribute("xr-mode-ui", "enabled: false");
  scene?.setAttribute("arjs", "trackingMethod: best;");

  var marker = document.createElement("a-marker-camera");
  marker?.setAttribute("type", "pattern");
  marker?.setAttribute("preset", "custom");
  marker?.setAttribute("emitevents", true);
  marker?.setAttribute("size", 45);
  marker?.setAttribute("smoothCount", 20);
  if (url) {
    marker?.setAttribute("url", url);
  }
  if (key) {
    marker?.setAttribute("url", getModelUrl({ key: key }));
  }
  scene?.appendChild(marker);

  // MARK: Add cursor to allow for clicking and basic interactivity with a scene on devices
  // const cursor = document.createElement("a-cursor");
  // marker.appendChild(cursor);

  // MARK: a-assets to preload if needed
  const assets = document.createElement("a-assets");
  scene?.appendChild(assets);

  return { marker, scene };
};

const addEntityRuntime = ({ markerRef, data }) => {
  var entity = document.createElement("a-entity");
  let extraEntities = [];
  if (data) {
    entity?.setAttribute("gltf-model", `url(${data?.url})`);
    entity?.setAttribute("scale", `${data?.defaultScale} ${data?.defaultScale} ${data?.defaultScale}`);
    entity?.setAttribute("position", `${data?.defaultPosition.x} ${data?.defaultPosition.y} ${data?.defaultPosition.z}`);
    entity?.setAttribute("rotation", `1 1 1`);
    entity?.setAttribute("z-index", "9999");
    entity?.setAttribute("anchored", "persistent: true");
    markerRef.current.appendChild(entity);
    entity?.setAttribute("visible", "false");

    data?.extras?.forEach((_extra) => {
      if (_extra?.type.toLowerCase() === "ball-blue-small") {
        const aSphere = document.createElement("a-sphere");
        // aSphere?.setAttribute("anchored", "persistent: true");
        aSphere?.setAttribute("color", "blue");
        aSphere?.setAttribute("radius", "1");
        aSphere?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        aSphere?.setAttribute("class", "sphere");
        aSphere?.setAttribute("sphere-click", "");

        aSphere?.setAttribute(
          "position",
          `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`
        );
        aSphere?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(aSphere);
        aSphere?.setAttribute("visible", "false");
        extraEntities.push(aSphere);
      } else if (_extra?.type.toLowerCase() === "box-red-small") {
        const box = document.createElement("a-box");
        // box?.setAttribute("anchored", "persistent: true");
        box?.setAttribute("color", "red");
        box?.setAttribute("scale", `${_extra?.defaultScale} ${_extra?.defaultScale} ${_extra?.defaultScale}`);
        box?.setAttribute("class", "box");
        box?.setAttribute("box-click", "");
        box?.setAttribute("position", `${_extra?.defaultPosition.x} ${_extra?.defaultPosition.y} ${_extra?.defaultPosition.z}`);
        box?.setAttribute("z-index", "9999");
        markerRef.current.appendChild(box);
        box?.setAttribute("visible", "false");
        extraEntities.push(box);
      }
    });
  }
  return { entity, extraEntities };
};

const disposeAllEntities = () => {
  // console.log("disposeAllEntities");
  const entities = document.querySelectorAll("a-entity");
  // const markers = document.querySelectorAll("a-marker");
  const markers = document.querySelectorAll("a-marker-camera");
  entities?.forEach((entity) => {
    entity.object3DMap.mesh.dispose();
    entity.parentNode.removeChild(entity);
  });

  markers?.forEach((marker) => {
    marker.parentNode.removeChild(marker);
  });
};

// const DATA_LIST = [
//   {
//     region: "ssp",
//     id: "mei-ho-house",
//     name: "石硤尾邨 - 美荷樓",
//     entity: {
//       ancient: {
//         id: "mei-ho-house-ancient",
//         // url: "https://survey.reality-connect.tech/models/mei-ho-house/Ancient-c.glb",
//         url: `${httpEndpoint}/models/mei-ho-house/Ancient-c.glb`,
//         videos: [{ name: "石硤尾邨 - 美荷樓 - 翻新前", videoUrl: `${httpEndpoint}/videos/mei-ho-house/Ancient.mp4` }],
//         desc: "美荷樓的誕生源於1953年12月25日夜晚在石硤尾發生的一場大火（後通稱「石硤尾大火」）。大火發生在石硤尾山邊寮屋區，使接近58,000人喪失家園。為了向災民提供長期的房屋設施，包括美荷樓在內的首批8座六層高的徙置大廈應運而生。為了改善居民生活，邨內其他地方已進行重建，而這座被列為香港二級歷史建築的美荷樓則於2005年關閉，直至2013年才改建成為青年旅舍，並設立了1間生活館。當前，美荷樓主題展覽「歲月留情」更加入口述歷史及各類型展品，以描繪50至60年代基層市民的生活世態，復刻香港精神的原點。",
//         // url: "http://192.168.0.220:8004/models/mei-ho-house/Ancient-c.glb",
//         defaultScale: 1.5, //0.65,
//         // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+; z = far- near+
//         defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
//       },
//       modern: {
//         id: "mei-ho-house-modern",
//         // url: "https://survey.reality-connect.tech/models/mei-ho-house/Modern-c.glb",
//         url: `${httpEndpoint}/models/mei-ho-house/Modern-c.glb`,
//         videos: [{ name: "石硤尾邨 - 美荷樓 - 翻新後", videoUrl: `${httpEndpoint}/videos/mei-ho-house/Modern.mp4` }],
//         desc: "美荷樓生活館迎來全新面貌的展館，新展覽將會帶大家回到過去，以不同方式讓大眾仿如置身舊日社區，更投入地認識和了解居民生活情景、昔日街坊情和美荷樓的蜕變。歡迎前來參觀，發掘精彩展覽內容！由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。",
//         // url: "http://192.168.0.220:8004/models/mei-ho-house/Modern-c.glb",
//         defaultScale: 1.5, //0.65,
//         // defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0 }, // 0.5 // x = left- right+
//         defaultPosition: { x: 0, y: 0.5 + 0.2, z: 0.2 }, // 0.5
//       },
//     },
//   },
//   {
//     region: "ssp",
//     id: "jccac",
//     name: "石硤尾工廠大廈",
//     entity: {
//       ancient: {
//         id: "jccac-ancient",
//         // url: "https://survey.reality-connect.tech/models/jccac/Ancient-c.glb",
//         url: `${httpEndpoint}/models/jccac/Ancient-c.glb`,
//         videos: [{ name: "石硤尾工廠大廈 - 翻新前", videoUrl: `${httpEndpoint}/videos/jccac/Ancient.mp4` }],
//         desc: "JCCAC 的大樓前身是1977 年落成的政府工廈「石硤尾工廠大廈」，當時容納許多謔稱「山寨廠」的家庭式手工業。隨著香港輕工業逐漸式微，工廈單位逐漸被丟空，後得政府提供場地及香港賽馬會慈善信託基金贊助改建項目（並因此命名），這幢舊工廈搖身一變成為樓高九層佔地約二十萬平方呎的重要藝文空間，改建項目並榮獲香港建築師學會2008「全年境內建築大獎」。JCCAC是自負盈虧非牟利慈善團體、香港浸會大學附屬機構，及香港藝術發展局和香港藝術中心的策略夥伴。",
//         // url: "http://192.168.0.220:8004/models/jccac/Ancient-c.glb",
//         defaultScale: 0.04, // MARK: 0.02
//         defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
//       },
//       modern: {
//         id: "jccac-modern",
//         // url: "https://survey.reality-connect.tech/models/jccac/Modern-c.glb",
//         url: `${httpEndpoint}/models/jccac/Modern-c.glb`,
//         videos: [{ name: "石硤尾工廠大廈 - 翻新後", videoUrl: `${httpEndpoint}/videos/jccac/Modern.mp4` }],
//         desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
//         // url: "http://192.168.0.220:8004/models/jccac/Modern-c.glb",
//         defaultScale: 0.04, // MARK: 0.02
//         defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0.3 }, // 0.5
//       },
//     },
//   },
//   {
//     region: "ssp",
//     id: "garden-company",
//     name: "嘉頓深水埗總部",
//     entity: {
//       ancient: {
//         id: "garden-company-ancient",
//         url: `${httpEndpoint}/models/garden-company/ancient.glb`,
//         videos: [],
//         desc: "嘉頓有限公司（The Garden Company Limited，簡稱嘉頓）是一家在香港知名且歷史悠久的食品公司，主要生產麵包、餅乾和蛋糕等食品，其著名產品包括“生命麵包”和“雪芳蛋糕”等。公司成立於1926年，由張子芳和黃華岳創立，因在香港植物園討論計劃而得名“Garden”，後在不同地點擴展，並推出富含維生素和礦物質的創新產品如“生命麵包”。",
//         defaultScale: 0.5, // MARK: 12.0
//         defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
//       },
//     },
//   },
//   {
//     region: "tm",
//     id: "poloshan",
//     name: "屯門大峽谷 - 菠蘿山",
//     entity: {
//       ancient: {
//         id: "poloshan-ancient",
//         url: `${httpEndpoint}/models/poloshan/Ancient-c.glb`,
//         videos: [
//           { name: "菠蘿山 - 1", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#FFAC98" },
//           { name: "菠蘿山 - 2", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#D0F0C0" },
//           { name: "菠蘿山 - 3", videoUrl: `${httpEndpoint}/videos/poloshan/Ancient.mp4`, color: "#B5E2FF" },
//         ],
//         desc: "菠蘿山，又被稱為「香港的大峽谷」，位於良田坳峽谷，是屯門區的一座小山丘，海拔高達121米。它座落於山景邨和良景邨之間，每年吸引了許多香港人和遊客前來遠足、郊遊和拍照留念。菠蘿山是香港重要的本土文化和歷史景點，承載著豐富的歷史內涵和文化價值。過去，這個地區是屯門地區的農田和果園，而現在則成為人們休閒娛樂的場所。透過虛擬實景技術，我們可以透過手機更深入地了解菠蘿山地貌的獨特之處。",
//         defaultScale: 1.0, // MARK: 12.0
//         defaultPosition: { x: 0, y: 0.5 - 0.4, z: 0 },
//       },
//     },
//   },
// ];

const ARPage = () => {
  const { region, id } = useParams();
  const dataList = MODEL_ARS.filter((_d) => _d.region === region);
  const [deviceType, setDeviceType] = useState(getDeviceType());
  const [glbDict, setGlbDict] = useState(null);
  const canvasRef = useRef(null);
  const [objPosition, setObjPosition] = useState({ x: 0, y: 0, z: 0 });
  const [objScale, setObjScale] = useState(1.0);
  const isPinchingRef = useRef(false);
  const markerRef = useRef(null);
  const currentEntityRef = useRef(null);
  const currentExtraEntitiesRef = useRef([]);
  const [modelMisc, setModelMisc] = useState({ videos: [], desc: "" });
  const [currentEntityId, setCurrentEntityId] = useState(id);
  const currentEntityIdRef = useRef(null);
  const [isNew, setIsNew] = useState(false);
  const isNewRef = useRef(false);
  const lastTouchesRef = useRef([]);
  const rotationRef = useRef({});
  const scaleRef = useRef(1.0);
  const [debugMsg, setDebugMsg] = useState("");
  const [entities, setEntities] = useState([]);
  const entitiesRef = useRef([]);
  const rendererRef = useRef(null);
  const [isTutorial, setIsTutorial] = useState(false);
  const aVideoRef = useRef(null);
  const foundCountRef = useRef(Array(10).fill(0));
  const [cameraDevices, setCameraDevices] = useState([]);
  const [isVerticalRotate, setIsVerticalRotate] = useState(false);
  const isVerticalRotateRef = useRef(false);
  // const [isDisabledPreview, setIsDisabledPreview] = useState(true);
  // const [bodyWidthHeight, setBodyWidthHeight] = useState({ width: window.innerWidth, height: window.innerHeight });

  const appSingleton = new AppSingleton();
  const navigate = useNavigate();

  useEffect(() => {
    isVerticalRotateRef.current = isVerticalRotate;
  }, [isVerticalRotate]);

  const screenTouchStart = (evt) => {
    const touches = evt.touches;
    if (touches.length === 2) {
      isPinchingRef.current = true;
    }
    lastTouchesRef.current = touches;
  };

  const screenTouchMove = (evt) => {
    const touches = evt.touches;
    const { x, y, z } = currentEntityRef.current?.getAttribute("rotation");
    if (touches.length === 1) {
      const { clientX, clientY } = touches[0];
      // const _rotation = { x, y: y - (clientX - lastTouchesRef.current[0].clientX), z };
      const _rotation = {
        x: isVerticalRotateRef.current ? x - (clientY - lastTouchesRef.current[0].clientY) : x,
        y: y - (clientX - lastTouchesRef.current[0].clientX),
        z,
      };
      currentEntityRef.current?.setAttribute("rotation", _rotation);
      currentExtraEntitiesRef.current?.forEach((el) => {
        el?.setAttribute("rotation", _rotation);
      });
      rotationRef.current = _rotation;
    } else if (touches.length === 2 && lastTouchesRef.current.length === 2) {
      setObjScale(
        calculateDistance(evt.touches[0], evt.touches[1]) /
          calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1])
      );
      scaleRef.current *=
        calculateDistance(evt.touches[0], evt.touches[1]) /
        calculateDistance(lastTouchesRef.current[0], lastTouchesRef.current[1]);
    }
    lastTouchesRef.current = touches;
  };

  const screenTouchEnd = (evt) => {
    if (evt.touches.length < 2) {
      isPinchingRef.current = false;
    }
  };

  const videoLaoded = (evt) => {
    const video = document.querySelector("video");
    video?.addEventListener("playing", function () {
      console.log("Webcam stream started");
      setIsTutorial(true);
    });
  };

  const updateCurrentEntityId = (indexDelta) => {
    const entities = entitiesRef.current;
    const currentIndex = entities.findIndex((d) => d.id === currentEntityId);
    let newIndex = (currentIndex + indexDelta) % entities.length;
    if (newIndex < 0) {
      newIndex += entities.length;
    }
    setCurrentEntityId(entities[newIndex].id);
    currentEntityIdRef.current = entities[newIndex].id;
  };

  useEffect(() => {
    navigator.mediaDevices.enumerateDevices().then((devices) => {
      const cameras = devices.filter((device) => device.kind === "videoinput");
      setCameraDevices(cameras);
    });

    const { marker, scene } = addMarker({ url: `${httpEndpoint}/images/pattern-naac-map.patt` });
    markerRef.current = marker;

    if (isVideo) {
      var aVideo = document.createElement("a-video");
      aVideo?.setAttribute("width", `2.8`);
      aVideo?.setAttribute("height", `1.8`);
      aVideo?.setAttribute("position", `0 1 -2`);
      marker.appendChild(aVideo);
      aVideoRef.current = aVideo;
    }

    const dataListAddedEntity = dataList.reduce((prev, current) => {
      const ancientDict = current?.entity?.ancient;
      const modernDict = current?.entity?.modern;
      if (ancientDict) {
        const { entity: ancientEntity, extraEntities } = addEntityRuntime({ markerRef, data: ancientDict });
        ancientDict.entity = ancientEntity;
        ancientDict.extraEntities = extraEntities;
      }
      if (modernDict) {
        const { entity: modernEntity, extraEntities } = addEntityRuntime({ markerRef, data: modernDict });
        modernDict.entity = modernEntity;
        modernDict.extraEntities = extraEntities;
      }

      return [...prev, current];
    }, []);

    entitiesRef.current = dataListAddedEntity;
    setEntities(dataListAddedEntity);

    // MARK: if entry from listing-page with the entity id, then directly use that one instead of default 0
    if (id) {
      setCurrentEntityId(dataListAddedEntity[0].id);
      currentEntityIdRef.current = dataListAddedEntity[0].id;
    }

    const renderer = rendererRef.current;
    if (renderer) {
      renderer.componentWillUnmount = () => {};
    }
    const videoTag = renderer?.container.children["arjs-video"];

    window.addEventListener("arjs-video-loaded", videoLaoded);
    window.addEventListener("touchstart", screenTouchStart);
    window.addEventListener("touchmove", screenTouchMove);
    window.addEventListener("touchend", screenTouchEnd);

    marker.addEventListener("foundCount", (e) => {
      const _foundCount = e.detail.value ?? 0;
      let _a = foundCountRef.current;
      _a.shift();
      _a.push(_foundCount);
      if (_a.every((element) => element === _a[0])) {
        entitiesRef.current.forEach(({ id, name, entity }) => {
          entity?.ancient?.entity?.setAttribute("visible", false);
          entity?.ancient?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
          entity?.modern?.entity?.setAttribute("visible", false);
          entity?.modern?.extraEntities?.forEach((e) => {
            e?.setAttribute("visible", false);
          });
        });
      } else {
        entitiesRef.current.forEach(({ id, name, entity }) => {
          const ancientVisible = id.includes(currentEntityIdRef.current) && !isNewRef.current;
          if (entity?.ancient?.entity?.visible !== ancientVisible) {
            entity?.ancient?.entity?.setAttribute("visible", ancientVisible);
            entity?.ancient?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", ancientVisible);
            });
          }
          const modernVisible = id.includes(currentEntityIdRef.current) && isNewRef.current;
          if (entity?.modern?.entity?.visible !== modernVisible) {
            entity?.modern?.entity?.setAttribute("visible", modernVisible);
            entity?.modern?.extraEntities?.forEach((e) => {
              e?.setAttribute("visible", modernVisible);
            });
          }
        });
      }
      foundCountRef.current = _a;
      setIsTutorial(false);
    });

    return () => {
      window.removeEventListener("arjs-video-loaded", videoLaoded);
      window.removeEventListener("touchstart", screenTouchStart);
      window.removeEventListener("touchmove", screenTouchMove);
      window.removeEventListener("touchend", screenTouchEnd);
      window.removeEventListener("foundCount");
      // window.removeEventListener("markerLost");
      const _videoTag = videoTag ?? renderer.container.children["arjs-video"];
      if (_videoTag) {
        _videoTag.parentNode?.removeChild(_videoTag);
      }
      disposeAllEntities();
    };
  }, []);

  useEffect(() => {
    if (markerRef.current) {
      const childCount = markerRef.current.childElementCount;
      entitiesRef.current.forEach(({ id, name, entity }) => {
        entity?.ancient?.entity?.setAttribute("visible", id === currentEntityId && !isNew);
        entity?.modern?.entity?.setAttribute("visible", id === currentEntityId && isNew);
      });
      const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
      if (_entities.length > 0) {
        const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
        if (_entityDict) {
          _entityDict.entity?.setAttribute("rotation", rotationRef.current);
          // const { x, y, z } = _entity.getAttribute("scale");
          _entityDict.entity?.setAttribute(
            "scale",
            `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
              _entityDict.defaultScale * scaleRef.current
            }`
          );
          // MARK: Rotate and scale extras entities when visible
          _entityDict.extraEntities?.forEach((e) => {
            e?.setAttribute("rotation", rotationRef.current);
          });
          currentEntityRef.current = _entityDict.entity;
          currentExtraEntitiesRef.current = _entityDict.extraEntities;
          // MARK: Update Description
          setModelMisc({ desc: _entityDict.desc, videos: _entityDict.videos ?? "" });

          // MARK: update a-video src by currentEntityId
          if (isVideo) {
            aVideoRef.current.pause();
            aVideoRef.current.currentTime = 0;
            aVideoRef.current?.setAttribute("src", `#${_entityDict.id}`);
            aVideoRef.current.play();
          }
        }
      }
    }
    // setIsDisabledPreview(
    //   MODEL_PREVIEWS.filter((_preview) => _preview.key === `${currentEntityId}-${isNew ? "modern" : "ancient"}`).length === 0
    // );
  }, [currentEntityId, isNew]);

  useEffect(() => {
    currentEntityRef.current?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    currentExtraEntitiesRef.current?.forEach((el) => {
      el?.setAttribute("position", `${objPosition.x} ${objPosition.y} ${objPosition.z}`);
    });
  }, [objPosition]);

  useEffect(() => {
    const _entities = entitiesRef.current.filter((el) => el.id === currentEntityId);
    if (_entities.length > 0) {
      // setDebugMsg(`scale: ${x} ${y} ${z}`);
      const _entityDict = isNew ? _entities[0].entity.modern : _entities[0].entity.ancient;
      if (_entityDict) {
        _entityDict.entity?.setAttribute(
          "scale",
          `${_entityDict.defaultScale * scaleRef.current} ${_entityDict.defaultScale * scaleRef.current} ${
            _entityDict.defaultScale * scaleRef.current
          }`
        );
      }
    }
  }, [objScale, isNew]);

  return (
    <Box sx={{ overflow: "hidden" }}>
      {isTutorial ? (
        <Box
          sx={{
            position: "absolute",
            top: 0,
            left: 0,
            width: "100%",
            height: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            zIndex: 999,
            overflow: "hidden",
          }}
        >
          <img
            id="paper"
            src="/images/paper.png"
            alt="paper"
            style={{
              position: "absolute",
              width: 600,
              height: 300,
            }}
          />
          <img
            id="hold-and-scan"
            src="/images/hold-to-scan.png"
            alt="Hold And Scan"
            style={{
              position: "absolute",
              width: 300,
              height: 300,
              animation: "moveLeftRight 5s ease-in-out infinite",
            }}
          />
        </Box>
      ) : null}
      <Switch
        sx={{ position: "absolute", zIndex: 999, right: 16, top: 16 }}
        checked={isVerticalRotate}
        onChange={(evt) => setIsVerticalRotate(evt.target.checked)}
      />
      <IntroductoryAvatar
        sx={{
          position: "absolute",
          bottom: 128,
          right: 16,
        }}
        {...modelMisc}
      />
      <Fab
        color={isNew ? "primary" : "#AAAAAA"}
        aria-label="Furnish"
        sx={{
          position: "absolute",
          right: 16,
          bottom: 64,
          zIndex: 999,
          backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          "&:hover": {
            backgroundColor: isNew ? appSingleton.themeColor.foreColor.main : "#FFF",
          },
        }}
        onClick={() => {
          setIsNew((_prev) => {
            isNewRef.current = !_prev;
            return !_prev;
          });
        }}
      >
        <ColorLens />
      </Fab>

      <Grid
        container
        justifyContent="space-between"
        sx={{ position: "absolute", top: 32, right: 0, left: 0, display: "flex", width: "100%", zIndex: 999 }}
      >
        <Fab
          aria-label="back"
          sx={{
            ml: 4,
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            color: "#FFF",
            "&:hover": {
              backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            },
          }}
          onClick={() => {
            window.location.href = backendEndPoint;
          }}
        >
          <ArrowBackIosNew />
        </Fab>
        <Box
          sx={{
            borderRadius: 16,
            backgroundColor: appSingleton.themeColor.foreColor.main,
            display: "flex",
            justifyContent: "center",
            pl: 4,
            pr: 4,
          }}
        >
          <Typography sx={{ fontSize: { xs: 18, sm: 24, md: 36 }, color: "#FFF", display: "flex", alignItems: "center" }}>
            {entities.filter((el) => el.id === currentEntityId).length > 0
              ? entities.filter((el) => el.id === currentEntityId)[0].name
              : "No Name"}
          </Typography>
        </Box>
        <Box sx={{ width: "56px", height: "56px", mr: 4 }}></Box>
      </Grid>
      <Box
        sx={{
          position: "absolute",
          left: 4,
          bottom: 4,
          width: "100%",
          height: "64px",
          zIndex: 999,
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        {dataList.length > 1 ? (
          <Button
            variant="contained"
            startIcon={<ArrowBackIosNew />}
            sx={{
              ml: 2,
              borderRadius: 8,
              backgroundColor: appSingleton.themeColor.foreColor.main,
              "&:hover": {
                backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
              },
            }}
            onClick={() => {
              updateCurrentEntityId(-1);
            }}
          >
            上一個回憶
          </Button>
        ) : null}

        <Button
          disabled={
            MODEL_PREVIEWS.filter((_preview) => _preview.key === `${currentEntityId}-${isNew ? "modern" : "ancient"}`)
              .length === 0
          }
          variant="contained"
          startIcon={deviceType === "ios" ? <ViewInAr /> : <ThreeDRotation />}
          sx={{
            borderRadius: 8,
            backgroundColor: appSingleton.themeColor.foreColor.main,
            "&:hover": {
              backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
            },
          }}
          onClick={() => {
            // TODO: map with target glb dict
            const _glbDicts = MODEL_PREVIEWS.filter(
              (_preview) => _preview.key === `${currentEntityId}-${isNew ? "modern" : "ancient"}`
            );
            if (_glbDicts.length > 0) {
              if (deviceType === "ios") {
                // window.location.href = `/models/preview/${_glbDicts[0]?.key}.usdz`;
                window.open(`/models/preview/${_glbDicts[0]?.key}.usdz`, "_blank", "");
              } else {
                setGlbDict(_glbDicts[0]);
              }
            }
          }}
        >
          {deviceType === "ios" ? "沉浸模式" : "模型展示"}
        </Button>
        {dataList.length > 1 ? (
          <Button
            variant="contained"
            endIcon={<ArrowForwardIos />}
            sx={{
              mr: 2,
              borderRadius: 8,
              backgroundColor: appSingleton.themeColor.foreColor.main,
              "&:hover": {
                backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
              },
            }}
            onClick={() => {
              updateCurrentEntityId(1);
            }}
          >
            下一個回憶
          </Button>
        ) : null}
      </Box>
      {/* <Button
        variant="contained"
        startIcon={<ArrowBackIosNew />}
        sx={{
          position: "absolute",
          left: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(-1);
        }}
      >
        上一個回憶
      </Button>
      <Button
        variant="contained"
        startIcon={deviceType === "ios" ? <ViewInAr /> : <ThreeDRotation />}
        sx={{
          position: "absolute",
          left: "50%",
          transform: "translate(-50%, -50%)",
          bottom: 4,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          setGlbDict({
            key: "jccac-ancient-c",
            name: "石硤尾大廈 (翻新前)",
            scale: { x: 0.05, y: 0.05, z: 0.05 },
            position: { x: 0.0, y: -2.0, z: 0.0 },
          });
        }}
      >
        {deviceType === "ios" ? "沉浸模式" : "模型展示"}
      </Button>
      <Button
        variant="contained"
        endIcon={<ArrowForwardIos />}
        sx={{
          position: "absolute",
          right: 16,
          bottom: 16,
          zIndex: 999,
          display: "flex",
          alignItems: "center",
          borderRadius: 8,
          backgroundColor: appSingleton.themeColor.foreColor.main,
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          updateCurrentEntityId(1);
        }}
      >
        下一個回憶
      </Button> */}
      <Box
        sx={{
          position: "absolute",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          backgroundColor: "#F5F5f5",
          display: "flex",
          justifyContent: "center",
          alignItems: "center",
          zIndex: -999,
          overflow: "hidden",
        }}
      >
        <Box>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            軟件需要相機權限，
          </Typography>
          <Typography sx={{ fontSize: { xs: 24, sm: 36, md: 48 }, m: 8, textAlign: "center", whiteSpace: "pre-wrap" }}>
            才能為您呈現擴增實境元素。
          </Typography>
        </Box>
      </Box>
      <Box sx={{ position: "absolute", left: 0, top: 0, zIndex: 9999, backgroundColor: "white" }}>
        <Typography> {`${debugMsg}`}</Typography>
      </Box>

      <Dialog
        open={!!glbDict}
        onClose={() => setGlbDict(null)}
        PaperProps={{
          style: { width: "95%", backgroundColor: "#cccccc" },
        }}
      >
        <DialogContent>
          <Canvas ref={canvasRef} style={{ width: "100%", height: "80vh" }}>
            <ambientLight intensity={2.0} />
            <pointLight position={[2, 2, 0]} intensity={100} decay={2} distance={30} />
            {glbDict ? <ModelPreview glbDict={glbDict} canvasRef={canvasRef} /> : null}
            <OrbitControls />
            <Html
              style={{ display: "flex", justifyContent: "center", alignItems: "center", width: "100%", textAlign: "center" }}
            >
              <h1
                style={{
                  whiteSpace: "nowrap",
                  color: "white",
                  textShadow: "-2px -2px 0 black, 2px -2px 0 black,-2px 2px 0 black, 2px 2px 0 black",
                  margin: 0,
                }}
              >
                {glbDict?.name}
              </h1>
            </Html>
          </Canvas>
        </DialogContent>
      </Dialog>

      <AFrameRenderer
        arToolKit={{
          sourceType: "webcam",
          tangoPointCloudEnabled: true,
          sourceWidth: 414,
          displayWidth: 414,
          canvasWidth: 414,
        }}
        renderer="logarithmicDepthBuffer: true; precision: medium;"
        ref={rendererRef}
      />
    </Box>
  );
};

const f = () => {};

export { ARPage, f };
