import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Card, CardContent, Divider, Fab, Fade, Stack, Typography } from "@mui/material";
import { ArrowBackIosNew, ArrowForwardIos } from "@mui/icons-material";
import { getRoute } from "../utils/CommonUtils";
import { AppSingleton } from "../services/user_config";

const BackgroundIntroductionPage = () => {
  const navigate = useNavigate();

  const appSingleton = new AppSingleton();

  return (
    <Box sx={{ width: "100%", height: "100vh", display: "flex", justifyContent: "center", alignItems: "center" }}>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          overflow: "hidden",
          zIndex: -1,
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: 'url("/images/landing-background-image.png")',
            opacity: 0.8,
            backgroundSize: "cover",
            backgroundPosition: "center",
            animation: "zoomAnimation 15s ease-in-out infinite",
            transformOrigin: "center center",
            "@keyframes zoomAnimation": {
              "0%": {
                transform: "scale(1)",
              },
              "50%": {
                transform: "scale(1.05)",
              },
              "100%": {
                transform: "scale(1)",
              },
            },
          }}
        ></Box>
      </Box>
      <Fab
        aria-label="back"
        sx={{
          position: "absolute",
          top: 32,
          left: 32,
          backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          color: "#FFF",
          "&:hover": {
            backgroundColor: `${appSingleton.themeColor.foreColor.main}`,
          },
        }}
        onClick={() => {
          navigate(`${getRoute("home-page")}`);
        }}
      >
        <ArrowBackIosNew />
      </Fab>
      <Card sx={{ width: "80%", height: "80%" }}>
        <CardContent sx={{ ml: 2, mr: 2, mt: 2 }}>
          <Typography sx={{ fontSize: 32 }}>鄰舍輔導會</Typography>
        </CardContent>
        <Divider sx={{ ml: 3, mr: 3 }} />
        <CardContent sx={{ ml: 2, mr: 2 }}>
          <Typography sx={{ fontSize: 16 }}>
            鄰舍輔導會簡介鄰舍輔導會於1968年成立，為一間紮根基層，以服務弱勢社群為己任的註冊非牟利慈善機構，為基層社區提供多元而到位的社會服務，提升個人及家庭的能力感；並連繫社會資源，推動關愛互助社區。服務對象包括嬰幼兒、兒童、青少年、長者、殘疾人士、低收入家庭等，亦有提供教育及醫療服務，以及推展社會企業和內地服務。目前本會共有81個服務單位​，去年年度服務人次超過233.6萬。
          </Typography>
        </CardContent>

        <CardContent sx={{ ml: 2, mr: 2, mt: 4 }}>
          <Typography sx={{ fontSize: 32 }}>Reality Connect</Typography>
        </CardContent>
        <Divider sx={{ ml: 3, mr: 3 }} />
        <CardContent sx={{ ml: 2, mr: 2 }}>
          <Typography sx={{ fontSize: 16 }}>
            Reality
            Connect帶你沉浸在無盡的世界中。採用我們平台快速實現虛擬實境部署，迎接娛樂的未來。沉浸在虛擬世界，盡情體驗與環境和角色的互動，彷彿身臨其境。現在就開啟你的冒險，探索一個無限可能的世界！
            讓你的想像翱翔，體驗前所未有的刺激與樂趣！
          </Typography>
        </CardContent>
      </Card>
    </Box>
  );
};

const f = () => {};

export { BackgroundIntroductionPage, f };
