import axios from "axios";

const isDebug = process.env.REACT_APP_DEBUG === "true";
const backendEndPoint = isDebug ? process.env.REACT_APP_BACKEND_ENDPOINT_DEBUG : process.env.REACT_APP_BACKEND_ENDPOINT;
const backendPoint = isDebug ? process.env.REACT_APP_BACKEND_PORT_DEBUG : process.env.REACT_APP_BACKEND_PORT;

const axiosApiInstance = axios.create();
let httpEndpoint = `${backendEndPoint}`;
if (backendPoint.length > 0) {
  httpEndpoint += `:${backendPoint}`;
}
let wsEndpoint = `${backendEndPoint}`;
if (backendPoint.length > 0) {
  wsEndpoint += `:${backendPoint}`;
}

// Request interceptor for API calls
axiosApiInstance.interceptors.request.use(
  async (config) => {
    config.headers = {
      //   Authorization: `Bearer ${getAccessToken()}`,
      Accept: "application/json",
      // "Content-Type": "application/x-www-form-urlencoded",
      "Content-Type": "application/json",
    };
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

// Response interceptor for API calls
axiosApiInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      //   const accessToken = await refreshAccessToken(getAccessToken());
      //   console.log("accessToken");
      //   console.log(accessToken);
      //   axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
      return axiosApiInstance(originalRequest);
    }
    return Promise.reject(error);
  }
);

const send_messageApi = async ({ message }) => {
  const response = axiosApiInstance.post(`${httpEndpoint}/api/v1/project/send_message`, {
    message,
  });
  return (await response).data;
};

export { backendEndPoint, httpEndpoint, isDebug, send_messageApi, wsEndpoint };
