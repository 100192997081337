import { Routes, Route, useNavigate } from "react-router-dom";
import { Box, Grid } from "@mui/material";
import routes from "./routes";
import { AppSingleton } from "../services/user_config";

const ContainerDrawer = () => {
  const navigate = useNavigate();
  var appSingleton = new AppSingleton();
  appSingleton.navigate = navigate;

  return (
    <>
      <Box>
        <Grid
          sx={{
            position: "fixed",
            width: "100%",
            bottom: 0,
            zIndex: 99,
          }}
        ></Grid>
        <Routes>
          {routes.map(({ name, key, icon, route, component }) => (
            <Route key={key} path={route} element={component}></Route>
          ))}
        </Routes>
      </Box>
    </>
  );
};

export { ContainerDrawer };
