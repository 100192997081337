import React from "react";
import ReactDOM from "react-dom/client";
import App from "./App";
import "./index.css";
// import reportWebVitals from "./reportWebVitals";

import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";

if (typeof global.__DEV__ === "undefined") {
  Object.defineProperty(global, "__DEV__", {
    get: () => true,
    configurable: true,
  });
}

const firebaseConfig = {
  apiKey: "AIzaSyDEpeKRZMqdB7I-56XobPcqRnthbdKbNes",
  authDomain: "reality-connect-website.firebaseapp.com",
  projectId: "reality-connect-website",
  storageBucket: "reality-connect-website.appspot.com",
  messagingSenderId: "840632831971",
  appId: "1:840632831971:web:bae1e887a2293f00db1ad6",
  measurementId: "G-4069T8CJM9",
};

const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(<App />);

// reportWebVitals();
