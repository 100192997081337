import { AppSingleton } from "../services/user_config";

const fetchWithProgress = (url, onProgress) => {
  return new Promise((resolve, reject) => {
    fetch(url)
      .then((response) => {
        const contentLength = response.headers.get("Content-Length");
        const total = contentLength ? parseInt(contentLength, 10) : null;
        const reader = response.body.getReader();
        let received = 0;
        let data = [];
        function read() {
          return reader.read().then(({ done, value }) => {
            if (done) {
              const blob = new Blob(data);
              const newResponse = new Response(blob, response);
              newResponse.blobValue = blob; // Attach the Blob to the new response object
              resolve(newResponse);
              return;
            }
            received += value.length;
            onProgress(received, total);
            data.push(value);
            return read();
          });
        }
        read().catch(reject);
      })
      .catch(reject);
  });
};

const downloadModel = ({ url, key }) => {
  console.log("downloadModel");
  const item = localStorage.getItem(key);
  if (item === null) {
    fetchWithProgress(url, (received, total) => {
      if (total) {
        const progress = Math.round((received / total) * 100);
        console.log(`Download progress: ${progress}%`);
      } else {
        console.log("Download progress: unknown");
      }
    })
      .then((response) => response.blob())
      .then((blob) => {
        console.log(`setting ${key}`);
        const dataURL = URL.createObjectURL(blob);
        localStorage.setItem(key, dataURL);
      })
      .catch((error) => {
        console.log("download error");
        console.log(error);
      });
  }
};

const getModelUrl = ({ key }) => {
  const objectUrl = localStorage.getItem(key);
  return objectUrl;
};

export { downloadModel, getModelUrl };
