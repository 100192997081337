import React, { useState, useEffect, useRef } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Box, Button, Fade, Stack, Typography } from "@mui/material";
import { getRoute } from "../utils/CommonUtils";
import { downloadModel } from "../utils/ModelUtils";
import { AppSingleton } from "../services/user_config";

// const dataList = [
//   {
//     id: "mei-ho-house",
//     name: "石硤尾邨 - 美荷樓",
//     entity: {
//       ancient: {
//         id: "mei-ho-house-ancient",
//         url: "https://survey.reality-connect.tech/models/mei-ho-house/Ancient-c.glb",
//         desc: "美荷樓的誕生源於1953年12月25日夜晚在石硤尾發生的一場大火（後通稱「石硤尾大火」）。大火發生在石硤尾山邊寮屋區，使接近58,000人喪失家園。為了向災民提供長期的房屋設施，包括美荷樓在內的首批8座六層高的徙置大廈應運而生。為了改善居民生活，邨內其他地方已進行重建，而這座被列為香港二級歷史建築的美荷樓則於2005年關閉，直至2013年才改建成為青年旅舍，並設立了1間生活館。當前，美荷樓主題展覽「歲月留情」更加入口述歷史及各類型展品，以描繪50至60年代基層市民的生活世態，復刻香港精神的原點。",
//         // url: "http://192.168.0.220:8004/models/mei-ho-house/Ancient-c.glb",
//         defaultScale: 0.06,
//       },
//       modern: {
//         id: "mei-ho-house-modern",
//         url: "https://survey.reality-connect.tech/models/mei-ho-house/Modern-c.glb",
//         desc: "美荷樓生活館迎來全新面貌的展館，新展覽將會帶大家回到過去，以不同方式讓大眾仿如置身舊日社區，更投入地認識和了解居民生活情景、昔日街坊情和美荷樓的蜕變。歡迎前來參觀，發掘精彩展覽內容！由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。由第一代「H型」徙置大廈活化而成，提供129個由原公屋單位改建的房間，設有懷舊主題房，讓你親身體驗本地文化。旅舍內的特色社區博物館「美荷樓生活館」，透過模擬單位、昔日生活場景，帶你穿越時光隧道，認識香港公屋歷史，及50-70年代石硤尾徙置區居民的生活故事。按此了解關於美荷樓活化計劃。",
//         // url: "http://192.168.0.220:8004/models/mei-ho-house/Modern-c.glb",
//         defaultScale: 0.06,
//       },
//     },
//   },
//   {
//     id: "jccac",
//     name: "石硤尾工廠大廈",
//     entity: {
//       ancient: {
//         id: "jccac-ancient",
//         url: "https://survey.reality-connect.tech/models/jccac/Ancient-c.glb",
//         desc: "JCCAC 的大樓前身是1977 年落成的政府工廈「石硤尾工廠大廈」，當時容納許多謔稱「山寨廠」的家庭式手工業。隨著香港輕工業逐漸式微，工廈單位逐漸被丟空，後得政府提供場地及香港賽馬會慈善信託基金贊助改建項目（並因此命名），這幢舊工廈搖身一變成為樓高九層佔地約二十萬平方呎的重要藝文空間，改建項目並榮獲香港建築師學會2008「全年境內建築大獎」。JCCAC是自負盈虧非牟利慈善團體、香港浸會大學附屬機構，及香港藝術發展局和香港藝術中心的策略夥伴。",
//         // url: "http://192.168.0.220:8004/models/jccac/Ancient-c.glb",
//         defaultScale: 0.02,
//       },
//       modern: {
//         id: "jccac-modern",
//         url: "https://survey.reality-connect.tech/models/jccac/Modern-c.glb",
//         desc: "賽馬會創意藝術中心（JCCAC）是香港唯一由整幢廠廈活化而成的垂直藝術村兼藝術中心，有140 個各類型的藝術家和文化團體成立工作室進駐，以視覺藝術為主，並預留近四份之一的工作室培育具潛質而剛起步的新晉藝術家。JCCAC亦是一所對外開放的藝術中心，以親切環境及創意氛圍見稱，定期舉辦展覽、演出、導賞和工作坊等活動。場內設有兩層「藝廊」展覽廳、提供表演活動的「黑盒劇場」，分佈各樓層還有不少特色的展示空間，圍繞中央庭園有茶藝館、咖啡室、創意工藝店等。",
//         // url: "http://192.168.0.220:8004/models/jccac/Modern-c.glb",
//         defaultScale: 0.02,
//       },
//     },
//   },
// ];

const LandingPage = () => {
  const navigate = useNavigate();
  const appSingleton = new AppSingleton();
  const [isDeviceVertical, setIsDeviceVertical] = useState(true);

  useEffect(() => {
    setIsDeviceVertical(appSingleton.windowSize.height > appSingleton.windowSize.width);
  }, [appSingleton.windowSize]);

  // useEffect(() => {
  //   localStorage.clear();
  //   dataList.forEach((el) => {
  //     const entity = el.entity;
  //     const ancient = entity.ancient;
  //     const modern = entity.modern;
  //     downloadModel({ url: ancient.url, key: ancient.id });
  //     downloadModel({ url: modern.url, key: modern.id });
  //   });
  //   downloadModel({ url: "https://survey.reality-connect.tech/images/pattern-naac-map.patt", key: "marker" });
  // }, []);

  // useEffect(() => {
  //   appSingleton.windowSize = {
  //     width: window.innerWidth,
  //     height: window.innerHeight,
  //   };
  //   appSingleton.windowSize
  //   const handleResize = () => {
  //     console.log("Window resized");
  //   };

  //   // const handleRotation = () => {
  //   //   console.log("Device rotated");
  //   // };

  //   window.addEventListener("resize", handleResize);
  //   // window.addEventListener("orientationchange", handleRotation);

  //   return () => {
  //     window.removeEventListener("resize", handleResize);
  //     // window.removeEventListener("orientationchange", handleRotation);
  //   };
  // }, []);

  return (
    <Box
      sx={{
        position: "absolute",
        width: "100%",
        height: "100vh",
        overflow: "hidden",
      }}
    >
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          // backgroundImage: 'url("/images/landing-background-image.png")',
          // backgroundPosition: "center",
          overflow: "hidden",
          backgroundColor: "#171717",
        }}
      >
        <Box
          sx={{
            width: "100%",
            height: "100%",
            backgroundImage: 'url("/images/landing-background-image.png")',
            opacity: 0.8,
            backgroundSize: "cover",
            backgroundPosition: "center",
            animation: "zoomAnimation 15s ease-in-out infinite",
            transformOrigin: "center center",
            "@keyframes zoomAnimation": {
              "0%": {
                transform: "scale(1.05)",
              },
              "50%": {
                transform: "scale(1.1)",
              },
              "100%": {
                transform: "scale(1.05)",
              },
            },
          }}
        ></Box>
      </Box>
      <Box
        sx={{
          position: "absolute",
          width: "100%",
          height: "100vh",
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
          justifyContent: "space-around",
        }}
      >
        {/* <Box
          sx={{
            display: "flex",
            alignItems: "center",
            // mb: 16,
          }}
        >
          <img src="/img/game-title.png" alt="歡樂健身夥伴" style={{ height: "20vh" }} />
        </Box> */}

        <Fade
          in={true}
          style={{
            transitionDelay: "200ms",
          }}
          {...{ timeout: 1000 }}
        >
          <Typography
            sx={{
              fontSize: (appSingleton.windowSize.height ?? 1280) * 0.1 * (isDeviceVertical ? 1.0 : 2.0), // MARK: target 128px
              color: "#FFF",
            }}
          >
            港●回憶
          </Typography>
        </Fade>
        <Box
          sx={{
            minWidth: "50%",
            maxWidth: "90%",
            // height: "40%",
            // flex: "0 0 50%",
            justifyContent: "flex-end",
            alignItems: "center",
            // alignSelf: "flex-end",
          }}
        >
          <Stack spacing={4}>
            <Fade in={true} style={{ transitionDelay: "1000ms" }} {...{ timeout: 1000 }}>
              <Button
                variant="contained"
                sx={{
                  fontSize: (appSingleton.windowSize.height ?? 1280) * 0.025 * (isDeviceVertical ? 1.0 : 2.0), // MARK: target 32px
                  borderRadius: "24px",
                  boxShadow: 15,
                  pl: 8,
                  pr: 8,
                  backgroundColor: appSingleton.themeColor.foreColor.main,
                }}
                onClick={() => {
                  // navigate(`${getRoute("ar-ssp-page")}/`);
                  // navigate(`${getRoute("ar-page")}/ssp`);
                  navigate(`${getRoute("listing-page")}/ssp`);
                }}
              >
                進入深水埗
              </Button>
            </Fade>

            <Fade in={true} style={{ transitionDelay: "1000ms" }} {...{ timeout: 1000 }}>
              <Button
                variant="contained"
                sx={{
                  fontSize: (appSingleton.windowSize.height ?? 1280) * 0.025 * (isDeviceVertical ? 1.0 : 2.0), // MARK: target 32px
                  borderRadius: "24px",
                  boxShadow: 15,
                  pl: 8,
                  pr: 8,
                  backgroundColor: appSingleton.themeColor.foreColor.main,
                }}
                onClick={() => {
                  // navigate(`${getRoute("ar-tm-page")}/`);
                  // navigate(`${getRoute("ar-page")}/tm`);
                  navigate(`${getRoute("listing-page")}/tm`);
                }}
              >
                進入屯門
              </Button>
            </Fade>

            <Fade
              in={true}
              style={{
                transitionDelay: "1500ms",
              }}
              {...{ timeout: 1000 }}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: (appSingleton.windowSize.height ?? 1280) * 0.025 * (isDeviceVertical ? 1.0 : 2.0), // MARK: target 32px
                  borderRadius: "24px",
                  boxShadow: 15,
                  pl: 8,
                  pr: 8,
                  backgroundColor: appSingleton.themeColor.foreColor.main,
                }}
                onClick={() => {
                  navigate(`${getRoute("model-preview-page")}/`);
                }}
              >
                模型展示
              </Button>
            </Fade>

            <Fade
              in={true}
              style={{
                transitionDelay: "1500ms",
              }}
              {...{ timeout: 1000 }}
            >
              <Button
                variant="contained"
                sx={{
                  fontSize: (appSingleton.windowSize.height ?? 1280) * 0.025 * (isDeviceVertical ? 1.0 : 2.0), // MARK: target 32px
                  borderRadius: "24px",
                  boxShadow: 15,
                  pl: 8,
                  pr: 8,
                  backgroundColor: appSingleton.themeColor.foreColor.main,
                }}
                onClick={() => {
                  navigate(`${getRoute("background-introduction-page")}/`);
                }}
              >
                背景簡介
              </Button>
            </Fade>
          </Stack>
        </Box>
        <Box
          sx={{
            textAlign: "center",
            position: "fixed",
            bottom: 0,
            left: 0,
            width: "100%",
          }}
        >
          <Typography
            variant="body1"
            color="white"
            // sx={{
            // }}
            //  fontWeight="bold"
          >
            &copy; {new Date().getFullYear()} Reality Connect Technologoy. All rights reserved.
          </Typography>
        </Box>
      </Box>
    </Box>
  );
};

const f = () => {};

export { LandingPage, f };
