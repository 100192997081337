import React, { useRef, useState, useEffect } from "react";
import {
  Avatar,
  Box,
  Button,
  Card,
  CardContent,
  Dialog,
  DialogContent,
  Grid,
  IconButton,
  Paper,
  Slide,
  Typography,
} from "@mui/material";
import { Add, Apps, PlayCircle, Remove } from "@mui/icons-material";
import { AppSingleton } from "../services/user_config";
import ReactPlayer from "react-player";

const AVATAR_WIDTH_HEIGHT = 56;

const IntroductoryVideoContainer = ({ video }) => {
  const [isVideoDialog, setIsVideoDialog] = useState(false);

  return (
    <Box>
      {/* <IconButton onClick={() => setIsVideoDialog(true)} sx={{ color: video?.color ?? "primary" }}>
        <PlayCircle /> {video?.name ?? ""}
      </IconButton> */}
      <Button
        variant="contained"
        color="primary"
        startIcon={<PlayCircle />}
        onClick={() => setIsVideoDialog(true)}
        sx={{ color: video?.color ?? "primary" }}
      >
        {video?.name ?? ""}
      </Button>
      {/* <Button variant="contained" color="primary" startIcon={<PlayCircle />}>
        {video?.name ?? ""}
      </Button> */}
      <Dialog maxWidth="md" open={isVideoDialog} onClose={() => setIsVideoDialog(false)}>
        <DialogContent sx={{ display: "flex", alignContent: "center", justifyContent: "center" }}>
          <ReactPlayer controls url={video?.videoUrl} />
        </DialogContent>
      </Dialog>
    </Box>
  );
};

const IntroductoryAvatar = ({ videos, desc, sx }) => {
  const [isShowCardOn, setIsShowCardOn] = useState(false);
  const [showCard, setShowCard] = useState(false);
  const descRef = useRef("");
  const appSingleton = new AppSingleton();
  const baseFontSize = 2;

  useEffect(() => {
    if (descRef.current !== desc) {
      setShowCard(false);
      setTimeout(() => {
        setShowCard(true);
      }, 100);
    }
  }, [desc]);

  return (
    <Box>
      <Avatar
        component={Paper}
        elevation={3}
        alt="_"
        // src="https://mui.com/static/images/avatar/1.jpg"
        // src="https://survey.reality-connect.tech/images/avatar-1.png"
        // src={isShowCardOn ? "/images/avatar-1.png" : "/images/avatar-1-disabled.png"}
        src="/images/avatar-1.png"
        onClick={() => {
          // setShowCard((_prev) => !_prev);
          setIsShowCardOn((_prev) => !_prev);
        }}
        sx={{
          width: `${AVATAR_WIDTH_HEIGHT}px`,
          height: `${AVATAR_WIDTH_HEIGHT}px`,
          zIndex: 999,
          ...sx,
        }}
      />

      <Slide direction="left" in={showCard & isShowCardOn} mountOnEnter unmountOnExit>
        <Card
          sx={{
            position: "absolute",
            width: "auto",
            left: 16,
            bottom: `${AVATAR_WIDTH_HEIGHT / 2 + 128}px`,
            right: `${AVATAR_WIDTH_HEIGHT + 16 + 8}px`,
            zIndex: 998,
          }}
        >
          <CardContent sx={{ mb: 0, pb: 0 }}>
            <Typography> {desc}</Typography>
            <Grid container spacing={2} sx={{ mt: 1 }}>
              {/* {videos.map((_video) => (
                <IntroductoryVideoContainer video={_video} />
              ))} */}
              {videos.map((_video, i) => (
                <Grid item key={`${_video.name}-${i}`}>
                  <IntroductoryVideoContainer video={_video} />
                </Grid>
              ))}
            </Grid>
          </CardContent>
        </Card>
      </Slide>
    </Box>
  );
};

const f = () => {};

export { IntroductoryAvatar, f };
