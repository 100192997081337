import React, { useState, useEffect } from "react";
import { BrowserRouter, Routes, Route, useNavigate } from "react-router-dom";
import { Box } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { ContainerDrawer } from "./basic/container-drawer";
import { AppSingleton } from "./services/user_config";
import { getDeviceType } from "./utils/CommonUtils";

const App = () => {
  var appSingleton = new AppSingleton();
  const [notifications, setNotifications] = useState([]);
  appSingleton.notifications = notifications;
  appSingleton.setNotifications = setNotifications;
  const [windowSize, setWindowSize] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  appSingleton.windowSize = windowSize;

  useEffect(() => {
    const handleResize = () => {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    };
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  useEffect(() => {
    if (notifications.length > 0) {
      console.log("!!! new event detected");
      console.log(notifications);
      const toastConfig = {
        autoClose: 5000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: false,
        draggable: false,
      };
      notifications.forEach((_notification) => {
        switch (_notification?.type) {
          case "error":
            toast.error(_notification.data?.message, toastConfig);
            break;
          default:
            toast.info(_notification.data?.message, toastConfig);
        }
      });
      setNotifications([]);
    }
  }, [notifications]);

  return (
    <Box
      sx={{
        // width: "100%", height: "100vh",
        overflow: "hidden",
        // ...(getDeviceType() === "android" ? { ...windowSize, position: "relative" } : {}),
        // ...(getDeviceType() === "android" ? { ...windowSize } : {}),
      }}
    >
      <BrowserRouter basename={process.env.PUBLIC_URL}>
        <ContainerDrawer />
      </BrowserRouter>
      <ToastContainer />
    </Box>
  );
};
export default App;
